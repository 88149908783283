import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Avatar,
  IconButton,
  ListItemAvatar,
  ListItemSecondaryAction,
  Tooltip,
  Button,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { workflowsActions, instancesActions } from "../../store/actions";

import Loading from "../Loading";

const styles = theme => ({
  section: {
    marginBottom: 18,
  },
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginBottom: 18,
  },
  button: {
    margin: 8,
  },
});

class Overview extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired,
    workflowId: PropTypes.string.isRequired,
    instanceId: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.props.getInstance(this.props.instanceId);
    this.props.getWorkflow(this.props.workflowId);
  }

  render() {
    const { classes } = this.props;

    if (this.props.isGettingWorkflow) {
      return <Loading text="Loading Workflow..." variant="h5" />;
    }

    if (this.props.isGettingInstance) {
      return <Loading text="Loading Instance..." variant="h5" />;
    }

    if (!this.props.workflow) {
      return <Typography gutterBottom>Workflow does not exist.</Typography>;
    }

    if (!this.props.instance) {
      return <Typography gutterBottom>Instance does not exist.</Typography>;
    }

    return (
      <Fragment>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            {this.props.workflow.name}
          </Typography>
          <Typography gutterBottom>
            {this.props.workflow.description}
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h5" gutterBottom>
            {this.props.instance.name}
          </Typography>
          <Typography gutterBottom>
            {this.props.instance.description}
          </Typography>
          <Typography gutterBottom>
            <b>Instance Status:</b>{" "}
            {this.props.instance.isActive ? "Active" : "Inactive"}
          </Typography>
        </div>
        <div className={classes.list}>
          <List
            component="nav"
            subheader={
              <ListSubheader>
                Templates ({this.props.instance.pages.length} Pages)
              </ListSubheader>
            }
          >
            {this.props.instance.pages.map((page, i) => (
              <ListItem
                button
                key={i}
                component={Link}
                to={`/workflows/${this.props.workflow._id}/${
                  this.props.instance._id
                }/dapp/${page.id}`}
              >
                <ListItemAvatar>
                  <Avatar>
                    <Typography>{i + 1}</Typography>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={page.name}
                  secondary={page.description}
                />

                <ListItemSecondaryAction>
                  {page.hidden ? (
                    <IconButton aria-label="Hidden">
                      <Tooltip title="Hidden" placement="top">
                        <VisibilityOffIcon />
                      </Tooltip>
                    </IconButton>
                  ) : (
                    <IconButton aria-label="Visible">
                      <Tooltip title="Visible" placement="top">
                        <VisibilityIcon />
                      </Tooltip>
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
        <div>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() =>
              this.props.updateInstance({
                fields: { isActive: !this.props.instance.isActive },
                id: this.props.instance._id,
              })
            }
          >
            {this.props.instance.isActive ? "Deactivate" : "Activate"} Instance
          </Button>
        </div>
        <div>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => this.props.deleteInstance(this.props.instance._id)}
            component={Link}
            to={`/workflows/${this.props.workflow._id}/`}
          >
            Delete Instance
          </Button>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  workflow: state.workflows.workflow,
  instance: state.instances.instance,
  isGettingWorkflow: state.workflows.isGettingWorkflow,
  isGettingInstance: state.instances.isGettingInstance,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getWorkflow: workflowsActions.getWorkflow,
      getInstance: instancesActions.getInstance,
      updateInstance: instancesActions.updateInstance,
      deleteInstance: instancesActions.deleteInstance,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Overview));
