import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Drawer as MUIDrawer, Typography, Toolbar } from "@material-ui/core";
import List from "@material-ui/core/List";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PersonIcon from "@material-ui/icons/Person";
import DomainIcon from "@material-ui/icons/Domain";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Theme from "./Theme";
import { drawerActions, authActions } from "../store/actions";

const styles = theme => ({
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: Theme.DrawerWidth,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: Theme.DrawerWidth,
  },
  userSection: {
    padding: theme.spacing.unit * 3,
    wordWrap: "break-word",
  },
});

class Drawer extends Component {
  componentDidMount() {
    if (!this.props.user) {
      this.props.getUser(this.props.userId);
    }
  }

  handleDrawerToggle = () => {
    this.props.toggleDrawer();
  };

  render() {
    const { classes, theme } = this.props;

    const showUser = this.props.user;

    const drawer = (
      <div>
        <Toolbar>
          <Typography variant="h6">BLOCKxStudio</Typography>
        </Toolbar>
        <Divider />
        <div className={classes.userSection}>
          {showUser && (
            <Fragment>
              <Typography variant="subtitle1">
                {this.props.user.name}
              </Typography>
              <Typography variant="caption" className={classes.userText}>
                {this.props.user.email}
              </Typography>
            </Fragment>
          )}
        </div>
        <Divider />
        <List>
          {[
            { text: "Dashboard", icon: <DashboardIcon />, to: "/" },
            { text: "Organization", icon: <DomainIcon />, to: "/organization" },
            { text: "Account", icon: <PersonIcon />, to: "/account" },
          ].map((item, index) => (
            <ListItem
              button
              key={index}
              component={Link}
              to={item.to}
              selected={this.props.pathname === item.to}
            >
              {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {[
            {
              text: "Log Out",
              icon: <ExitToAppIcon />,
              action: () => this.props.logout({ redirectTo: "/" }),
            },
          ].map((item, index) => (
            <ListItem button key={index} onClick={() => item.action()}>
              {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </div>
    );

    return (
      <nav className={classes.drawer}>
        {/* The implementation can be swap with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <MUIDrawer
            container={this.props.container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={this.props.isDrawerOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </MUIDrawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <MUIDrawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </MUIDrawer>
        </Hidden>
      </nav>
    );
  }
}

Drawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userId: state.auth.userId,
  isGettingUser: state.auth.isGettingUser,
  user: state.auth.user,
  isDrawerOpen: state.drawer.isOpen,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUser: authActions.getUser,
      toggleDrawer: drawerActions.toggle,
      logout: authActions.logout,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Drawer));
