import types from "./types";

const INITIAL = {
  instance: null,
  instances: null,
  isGettingInstance: false,
  isGettingInstances: false,
  isUpdatingInstance: false,
  isDeletingInstance: false,
  isCreatingInstance: false,
};

export const reducer = (state = INITIAL, action) => {
  switch (action.type) {
    case types.GET_INSTANCE_REQUEST:
      return {
        ...state,
        isGettingInstance: true,
      };
    case types.GET_INSTANCE_SUCCESS:
      return {
        ...state,
        instance: action.payload,
        isGettingInstance: false,
      };
    case types.GET_INSTANCE_FAILURE:
      return {
        ...state,
        instance: null,
        isGettingInstance: false,
      };

    case types.GET_INSTANCES_REQUEST:
      return {
        ...state,
        isGettingInstances: true,
      };
    case types.GET_INSTANCES_SUCCESS:
      return {
        ...state,
        instances: action.payload,
        isGettingInstances: false,
      };
    case types.GET_INSTANCES_FAILURE:
      return {
        ...state,
        instances: null,
        isGettingInstances: false,
      };

    case types.UPDATE_INSTANCE_REQUEST:
      return {
        ...state,
        isUpdatingInstance: true,
      };
    case types.UPDATE_INSTANCE_SUCCESS:
      return {
        ...state,
        instance: action.payload,
        isUpdatingInstance: false,
      };
    case types.UPDATE_INSTANCE_FAILURE:
      return {
        ...state,
        instance: null,
        isUpdatingInstance: false,
      };

    case types.DELETE_INSTANCE_REQUEST:
      return {
        ...state,
        isDeletingInstance: true,
      };
    case types.DELETE_INSTANCE_SUCCESS:
      return {
        ...state,
        instance: action.payload,
        isDeletingInstance: false,
      };
    case types.DELETE_INSTANCE_FAILURE:
      return {
        ...state,
        instance: null,
        isDeletingInstance: false,
      };

    case types.CREATE_INSTANCE_REQUEST:
      return {
        ...state,
        isCreatingInstance: true,
      };
    case types.CREATE_INSTANCE_SUCCESS:
      return {
        ...state,
        instance: action.payload,
        isCreatingInstance: false,
      };
    case types.CREATE_INSTANCE_FAILURE:
      return {
        ...state,
        instance: null,
        isCreatingInstance: false,
      };

    default:
      return state;
  }
};
