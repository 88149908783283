import types from "./types";

const INITIAL = {
  isOpen: false,
};

export const reducer = (state = INITIAL, action) => {
  switch (action.type) {
    case types.OPEN_DRAWER:
      return {
        ...state,
        isOpen: true,
      };
    case types.CLOSE_DRAWER:
      return {
        ...state,
        isOpen: false,
      };
    case types.TOGGLE_DRAWER:
      return {
        ...state,
        isOpen: !state.isOpen,
      };

    default:
      return state;
  }
};
