import axios from "axios";
import types from "./types";
import Constants from "../../Constants";
import { actions as snackbarActions } from "../snackbar";
import Utils from "../../Utils";

// Creators
export const actions = {
  getWorkflows,
  getWorkflow,
};

// Implementations
function getWorkflows({ query = null } = {}) {
  return dispatch => {
    dispatch(request());

    const stringifiedQuery = Utils.stringify(query);

    axios({
      method: "get",
      url: `/workflows?${stringifiedQuery}`,
      baseURL: Constants.BXS_API_URL,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error ${
              error.response.status
            }: Get workflows request failed.`,
            variant: "error",
          })
        );
      });
  };

  function request() {
    return { type: types.GET_WORKFLOWS_REQUEST };
  }
  function success(payload) {
    return { type: types.GET_WORKFLOWS_SUCCESS, payload };
  }
  function failure() {
    return { type: types.GET_WORKFLOWS_FAILURE };
  }
}

function getWorkflow(id) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "get",
      url: `/workflows/${id}`,
      baseURL: Constants.BXS_API_URL,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error ${
              error.response.status
            }: Get workflow by id failed.`,
            variant: "error",
          })
        );
      });
  };

  function request() {
    return { type: types.GET_WORKFLOW_REQUEST };
  }
  function success(payload) {
    return { type: types.GET_WORKFLOW_SUCCESS, payload };
  }
  function failure() {
    return { type: types.GET_WORKFLOW_FAILURE };
  }
}
