export default {
  DECODE_TOKEN_REQUEST: "DECODE_TOKEN_REQUEST",
  DECODE_TOKEN_SUCCESS: "DECODE_TOKEN_SUCCESS",
  DECODE_TOKEN_FAILURE: "DECODE_TOKEN_FAILURE",

  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",

  GET_ORGANIZATION_REQUEST: "GET_ORGANIZATION_REQUEST",
  GET_ORGANIZATION_SUCCESS: "GET_ORGANIZATION_SUCCESS",
  GET_ORGANIZATION_FAILURE: "GET_ORGANIZATION_FAILURE",

  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

  UPDATE_ORGANIZATION_REQUEST: "UPDATE_ORGANIZATION_REQUEST",
  UPDATE_ORGANIZATION_SUCCESS: "UPDATE_ORGANIZATION_SUCCESS",
  UPDATE_ORGANIZATION_FAILURE: "UPDATE_ORGANIZATION_FAILURE",

  LOGOUT: "LOGOUT",
};
