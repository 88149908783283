import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Typography, TextField, Button } from "@material-ui/core";
import Loading from "./Loading";
import { actions as authActions } from "../store/auth";
import Roles from "../Roles";
import InvitesList from "./InvitesList";
import InviteForm from "./InviteForm";
import UserList from "./UserList";

const styles = {
  nextFeatures: {
    marginTop: 16,
  },
  list: {},
  section: {
    marginTop: 24,
    marginBottom: 24,
  },
  saveButton: {
    marginTop: 24,
    marginBottom: 24,
  },
};

class ManageOrganization extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired,
  };

  state = {};

  componentDidMount() {
    this.props.getUser(this.props.userId);
    this.props.getOrganization(this.props.organizationId);
  }

  render() {
    const { classes } = this.props;

    if (this.props.isGettingUser) {
      return <Loading text="Loading User Details..." variant="h5" />;
    }

    if (this.props.isGettingOrganization) {
      return <Loading text="Loading Organization Details..." variant="h5" />;
    }

    if (!this.props.user) {
      return <Typography gutterBottom>User does not exist.</Typography>;
    }

    if (!this.props.organization) {
      return <Typography gutterBottom>Organization does not exist.</Typography>;
    }

    const customerRole = Roles.getRoleById(this.props.user.customerRole);

    return (
      <Fragment>
        <div className={classes.section}>
          <Typography variant="h5" color="secondary" gutterBottom>
            Details and Settings
          </Typography>
          <TextField
            label="Organization Name"
            defaultValue={this.props.organization.name}
            margin="normal"
            fullWidth
            onChange={event => this.setState({ orgName: event.target.value })}
          />
          <TextField
            label="Your Organization Role"
            value={customerRole.name}
            margin="normal"
            fullWidth
            disabled
          />
          <Typography>{customerRole.description}</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              this.props.updateOrganization({
                fields: { name: this.state.orgName },
                id: this.props.organizationId,
              })
            }
            className={classes.saveButton}
            disabled={
              !this.state.orgName ||
              this.state.orgName === this.props.organization.name
            }
          >
            Save and Update
          </Button>
        </div>
        <div className={classes.section}>
          <UserList />
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Add Members
          </Typography>
          {!customerRole.canInvite && (
            <Typography variant="subtitle1">
              As a {customerRole.name}, you cannot add other members to your
              organization.
            </Typography>
          )}
          {customerRole.canInvite && (
            <Fragment>
              <InvitesList
                filterRoles={customerRole.canInvite}
                className={classes.list}
              />
              <InviteForm
                roles={Roles.getRolesByIds(customerRole.canInvite)}
                organizationId={this.props.organization._id}
              />
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userId: state.auth.userId,
  organizationId: state.auth.organizationId,
  user: state.auth.user,
  organization: state.auth.organization,
  isGettingUser: state.auth.isGettingUser,
  isGettingOrganization: state.auth.isGettingOrganization,
  isUpdatingOrganization: state.auth.isUpdatingOrganization,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUser: authActions.getUser,
      getOrganization: authActions.getOrganization,
      updateOrganization: authActions.updateOrganization,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ManageOrganization));
