import types from "./types";

const INITIAL = {
  message: null,
  messages: null,
  isGettingMessage: false,
  isGettingMessages: false,
  isSendingMessage: false,
  isDeletingMessages: false,
  isCreatingMessage: false,
};

export const reducer = (state = INITIAL, action) => {
  switch (action.type) {
    case types.GET_MESSAGE_REQUEST:
      return {
        ...state,
        isGettingMessage: true,
      };
    case types.GET_MESSAGE_SUCCESS:
      return {
        ...state,
        message: action.payload,
        isGettingMessage: false,
      };
    case types.GET_MESSAGE_FAILURE:
      return {
        ...state,
        message: null,
        isGettingMessage: false,
      };

    case types.GET_MESSAGES_REQUEST:
      return {
        ...state,
        isGettingMessages: true,
      };
    case types.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.payload,
        isGettingMessages: false,
      };
    case types.GET_MESSAGES_FAILURE:
      return {
        ...state,
        messages: null,
        isGettingMessages: false,
      };

    case types.SEND_MESSAGE_REQUEST:
      return {
        ...state,
        isSendingMessage: true,
      };
    case types.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        message: action.payload,
        isSendingMessage: false,
      };
    case types.SEND_MESSAGE_FAILURE:
      return {
        ...state,
        message: null,
        isSendingMessage: false,
      };

    case types.DELETE_MESSAGES_REQUEST:
      return {
        ...state,
        isDeletingMessages: true,
      };
    case types.DELETE_MESSAGES_SUCCESS:
      return {
        ...state,
        isDeletingMessages: false,
      };
    case types.DELETE_MESSAGES_FAILURE:
      return {
        ...state,
        isDeletingMessages: false,
      };

    case types.CREATE_MESSAGE_REQUEST:
      return {
        ...state,
        isCreatingMessage: true,
      };
    case types.CREATE_MESSAGE_SUCCESS:
      return {
        ...state,
        message: action.payload,
        isCreatingMessage: false,
      };
    case types.CREATE_MESSAGE_FAILURE:
      return {
        ...state,
        message: null,
        isCreatingMessage: false,
      };

    case types.CREATE_MESSAGES_REQUEST:
      return {
        ...state,
        isCreatingMessages: true,
      };
    case types.CREATE_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.payload,
        isCreatingMessages: false,
      };
    case types.CREATE_MESSAGES_FAILURE:
      return {
        ...state,
        messages: null,
        isCreatingMessages: false,
      };

    default:
      return state;
  }
};
