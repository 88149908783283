import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      padding: `40px ${theme.spacing.unit * 4}px`,
    },
    [theme.breakpoints.up("md")]: {
      padding: `80px 40px`,
    },
  },
  grid: {
    maxWidth: theme.breakpoints.values.md,
    margin: "0 auto",
  },
  item2: {
    padding: `${theme.spacing.unit * 4}px 0px`,
  },
  body: {},
});

function MarketingGrid(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Grid container className={classes.grid}>
        <Grid item xs={12} sm={7}>
          <Typography variant="h4" gutterBottom>
            Modular Smart Contract Templates
          </Typography>
          <Typography variant="subtitle1">
            Quickly deploy blockchain distributed application solutions with
            robust, ready to use templates.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={7} className={classes.item2}>
          <Typography variant="h4" gutterBottom>
            Premium Engineering Services
          </Typography>
          <Typography variant="subtitle1">
            Blockchain engineers available for complex integration architecture
            and hybrid applications.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Typography variant="h4" gutterBottom>
            Private Blockchain Provisioning
          </Typography>
          <Typography variant="subtitle1">
            Maximize deployment speed, flexibility and security by launching
            your solution on a managed private blockchain network
            infrastructure.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

MarketingGrid.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MarketingGrid);
