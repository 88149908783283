import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { List as MUIList } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
});

class List extends Component {
  render() {
    const { classes } = this.props;

    if (!this.props.items) {
      return <Typography>List is empty.</Typography>;
    }

    return (
      <div className={classes.root}>
        <MUIList component="nav">
          {this.props.items.map((item, i) => (
            <ListItem button key={i} component={Link} to={item.redirectTo}>
              <ListItemText
                primary={item.primaryText}
                secondary={item.secondaryText}
              />
            </ListItem>
          ))}
        </MUIList>
      </div>
    );
  }
}

export default withStyles(styles)(List);
