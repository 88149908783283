import Cookies from "universal-cookie";

export default class Constants {
  static Cookies = new Cookies();

  static BXS_API_URL = Constants.GetAPIURL("remote");

  static BXS_HOMEPAGE_URL = Constants.GetHomepageURL("remote");

  static BXS_STUDIO_URL = Constants.GetStudioURL();

  static GetAPIURL(type) {
    return type === "local"
      ? "http://localhost:8080/1.0"
      : "https://api.blockxstudio.com/1.0";
  }

  static GetHomepageURL(type) {
    return type === "local"
      ? "http://localhost:3000"
      : "https://blockxstudio.com";
  }

  static GetStudioURL() {
    return process.env.NODE_ENV === "development"
      ? "http://localhost:3002"
      : "https://app.blockxstudio.com";
  }
}
