import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Button, TextField, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { instancesActions } from "../../../store/actions";

const styles = {
  root: {
    padding: 30,
    margin: "40px 20px",
    textAlign: "center",
  },
  mobileRoot: {
    padding: 10,
    margin: "40px 0px",
    textAlign: "center",
  },
  title: {},
  forms: {
    // padding: "60px 20px",
  },
  form: {
    flexGrow: 1,
  },
  button: {
    marginTop: 20,
    marginBottom: 10,
  },
};

class OrderInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactName: props.data.order.contact.name,
      contactPhone: props.data.order.contact.phone,
      contactEmail: props.data.order.contact.email,

      cargoName: props.data.order.cargo.name,
      cargoDescription: props.data.order.cargo.description,
      cargoQuantity: props.data.order.cargo.quantity,
      cargoPrice: props.data.order.cargo.price,
    };
  }

  forms() {
    const { classes } = this.props;

    return (
      <Grid container wrap="wrap" spacing={40} className={classes.forms}>
        {this.formContact()}
        {this.formCargo()}
      </Grid>
    );
  }

  isFormInvalid() {
    if (
      !this.state.contactName ||
      !this.state.contactPhone ||
      !this.state.contactEmail ||
      !this.state.cargoName ||
      !this.state.cargoDescription ||
      !this.state.cargoQuantity ||
      !this.state.cargoPrice
    ) {
      return "Cannot save: Form is incomplete";
    }

    return false;
  }

  errors() {
    return (
      <Typography color="secondary" variant="body1">
        {this.isFormInvalid()}
      </Typography>
    );
  }

  button() {
    const { classes } = this.props;

    const fields = {
      data: {
        ...this.props.instance.data,
        valid: {
          ...this.props.data.valid,
          order: true,
        },
        order: {
          contact: {
            name: this.state.contactName,
            phone: this.state.contactPhone,
            email: this.state.contactEmail,
          },
          cargo: {
            name: this.state.cargoName,
            description: this.state.cargoDescription,
            quantity: this.state.cargoQuantity,
            price: this.state.cargoPrice,
          },
        },
      },
    };

    return (
      <Button
        disabled={!!this.isFormInvalid()}
        className={classes.button}
        variant="contained"
        color="primary"
        component={Link}
        to={this.props.nextURL}
        onClick={() =>
          this.props.updateInstance({
            fields: fields,
            id: this.props.instance._id,
          })
        }
      >
        Save
      </Button>
    );
  }

  render() {
    return (
      <Fragment>
        {this.forms()}
        {!this.props.data.hasInitiated && this.button()}
        {this.errors()}
      </Fragment>
    );
  }

  formContact() {
    const { classes } = this.props;

    return (
      <Grid item md={6} className={classes.form}>
        <Typography
          variant="h6"
          color="secondary"
          className={classes.formTitle}
        >
          Contact Details
        </Typography>
        <TextField
          key="name"
          id="name"
          label="Contact Name"
          autoComplete="name"
          type="text"
          fullWidth
          margin="normal"
          defaultValue={this.state.contactName}
          onChange={event => {
            this.setState({ contactName: event.target.value });
          }}
        />
        <TextField
          key="contactPhone"
          id="contactPhone"
          label="Contact Phone"
          autoComplete="contactPhone"
          type="text"
          fullWidth
          margin="normal"
          helperText="Format phone number as '+1 xxx xxx xxxx'"
          defaultValue={this.state.contactPhone}
          onChange={event => {
            this.setState({ contactPhone: event.target.value });
          }}
        />
        <TextField
          key="contactEmail"
          id="contactEmail"
          label="Contact Email"
          autoComplete="contactEmail"
          type="text"
          fullWidth
          margin="normal"
          defaultValue={this.state.contactEmail}
          onChange={event => {
            this.setState({ contactEmail: event.target.value });
          }}
        />
      </Grid>
    );
  }

  formCargo() {
    const { classes } = this.props;

    return (
      <Grid item md={6} className={classes.form}>
        <Typography
          variant="h6"
          color="secondary"
          className={classes.formTitle}
        >
          Cargo
        </Typography>
        <TextField
          key="item"
          id="item"
          label="Item Name"
          autoComplete="item"
          type="text"
          fullWidth
          margin="normal"
          defaultValue={this.state.cargoName}
          onChange={event => {
            this.setState({ cargoName: event.target.value });
          }}
        />
        <TextField
          key="description"
          id="description"
          label="Description"
          autoComplete="description"
          type="text"
          fullWidth
          margin="normal"
          defaultValue={this.state.cargoDescription}
          onChange={event => {
            this.setState({ cargoDescription: event.target.value });
          }}
        />
        <TextField
          key="quantity"
          id="quantity"
          label="Quantity"
          autoComplete="quantity"
          type="text"
          fullWidth
          margin="normal"
          defaultValue={this.state.cargoQuantity}
          onChange={event => {
            this.setState({ cargoQuantity: event.target.value });
          }}
        />
        <TextField
          key="price"
          id="price"
          label="Price"
          autoComplete="price"
          type="text"
          fullWidth
          margin="normal"
          defaultValue={this.state.cargoPrice}
          onChange={event => {
            this.setState({ cargoPrice: event.target.value });
          }}
        />
      </Grid>
    );
  }
}

OrderInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  nextURL: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    instance: state.instances.instance,
    data: state.instances.instance.data,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateInstance: instancesActions.updateInstance,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OrderInformation));
