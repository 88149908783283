import axios from "axios";
import types from "./types";
import Constants from "../../Constants";
import { actions as snackbarActions } from "../snackbar";
import Utils from "../../Utils";

// Creators
export const actions = {
  getUsers,
  getUser,
  updateUser,
  deleteUser,
};

// Implementations
function getUsers({ query = null } = {}) {
  return dispatch => {
    dispatch(request());

    const stringifiedQuery = Utils.stringify(query);

    axios({
      method: "get",
      url: `/users?${stringifiedQuery}`,
      baseURL: Constants.BXS_API_URL,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error: Get users request failed.`,
            variant: "error",
          })
        );
      });
  };

  function request() {
    return { type: types.GET_USERS_REQUEST };
  }
  function success(payload) {
    return { type: types.GET_USERS_SUCCESS, payload };
  }
  function failure() {
    return { type: types.GET_USERS_FAILURE };
  }
}

function getUser(id) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "get",
      url: `/users/${id}`,
      baseURL: Constants.BXS_API_URL,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error: Get user by id failed.`,
            variant: "error",
          })
        );
      });
  };

  function request() {
    return { type: types.GET_USER_REQUEST };
  }
  function success(payload) {
    return { type: types.GET_USER_SUCCESS, payload };
  }
  function failure() {
    return { type: types.GET_USER_FAILURE };
  }
}

function updateUser({ fields = null, id = null } = {}) {
  return dispatch => {
    dispatch(request());
    if (!fields || !id) {
      dispatch(failure());
      dispatch(
        snackbarActions.open({
          message: `Action error: Cannot update user. Missing fields or id.`,
          variant: "error",
        })
      );
      return;
    }

    axios({
      method: "put",
      url: `/users/${id}`,
      baseURL: Constants.BXS_API_URL,
      data: fields,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error: Update user request failed.`,
            variant: "error",
          })
        );
      });
  };

  function request() {
    return { type: types.UPDATE_USER_REQUEST };
  }
  function success(payload) {
    return { type: types.UPDATE_USER_SUCCESS, payload };
  }
  function failure() {
    return { type: types.UPDATE_USER_FAILURE };
  }
}

function deleteUser(id) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "delete",
      url: `/users/${id}`,
      baseURL: Constants.BXS_API_URL,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error: Delete user by id failed.`,
            variant: "error",
          })
        );
      });
  };

  function request() {
    return { type: types.DELETE_USER_REQUEST };
  }
  function success(payload) {
    return { type: types.DELETE_USER_SUCCESS, payload };
  }
  function failure() {
    return { type: types.DELETE_USER_FAILURE };
  }
}
