import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import RootReducer from "./RootReducer";
export const history = createBrowserHistory();

// Middleware for intercepting and dispatching navigation actions
const middleware = applyMiddleware(thunk, routerMiddleware(history));

// When the extension is not installed, use Redux compose
const composer =
  process.env.NODE_ENV === "development" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const enhancers = composer(middleware);

// Passing initial state is useful for server-side rendering and great for SEO
export const store = createStore(RootReducer, enhancers);
