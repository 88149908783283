import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  TextField,
  Typography,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Loading from "./Loading";

import { actions as invitesActions } from "../store/invites";

const styles = {
  root: {
    maxWidth: 500,
    marginTop: 16,
  },
  textField: {
    margin: "5px 0",
  },
  button: {
    margin: "25px 0 10px",
  },
  rolesFormLabel: {
    marginTop: 30,
  },
};

class InviteForm extends Component {
  static protoTypes = {
    roles: PropTypes.arrayOf(PropTypes.object).isRequired,
    organizationId: PropTypes.string,
  };

  static defaultProps = {
    organizationId: null,
  };

  state = {
    name: null,
    email: null,
    role: null,
  };

  render() {
    const { isSendingInvite, classes } = this.props;

    if (isSendingInvite) {
      return <Loading text="Sending Invite" />;
    }

    return (
      <div className={classes.root}>
        <Typography color="secondary" variant="h5" gutterBottom>
          Send an Invite
        </Typography>
        <Typography variant="body2" gutterBottom>
          This form sends an email inviting the user to join your organization
          on BLOCKxStudio. They are given a unique sign-up code and link to
          create their account.
        </Typography>
        <TextField
          id="name"
          label="Name"
          fullWidth
          onChange={event => this.setState({ name: event.target.value })}
          className={classes.textField}
        />
        <TextField
          id="email"
          label="Email"
          fullWidth
          onChange={event => this.setState({ email: event.target.value })}
          className={classes.textField}
        />
        <FormLabel component="legend" className={classes.rolesFormLabel}>
          Choose Role for this User
        </FormLabel>
        <RadioGroup
          aria-label="roles"
          name="roles"
          value={this.state.role}
          onChange={event => this.setState({ role: event.target.value })}
        >
          {this.props.roles.map(role => (
            <FormControlLabel
              key={role.id}
              value={role.id}
              control={<Radio color="primary" />}
              label={role.name}
            />
          ))}
        </RadioGroup>
        <Button
          variant="contained"
          color="primary"
          onClick={() => this.handleSubmit()}
          className={classes.button}
          disabled={!this.isValid()}
        >
          Send Invite
        </Button>
      </div>
    );
  }

  isValid() {
    if (!this.state.email || this.state.email.length < 1) {
      return false;
    }

    if (!this.state.name || this.state.name.length < 1) {
      return false;
    }

    if (!this.state.role) {
      return false;
    }

    return true;
  }

  handleSubmit() {
    const { sendInvite } = this.props;

    const values = {
      name: this.state.name,
      email: this.state.email,
      role: this.state.role,
      organizationId: this.props.organizationId,
    };

    sendInvite({ fields: values });

    this.resetFormState();
  }

  resetFormState() {
    this.setState({
      name: null,
      email: null,
      role: null,
    });
  }
}

const mapStateToProps = state => {
  return {
    isSendingInvite: state.invites.isSendingInvite,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendInvite: invitesActions.sendInvite,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InviteForm));
