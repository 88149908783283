import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@material-ui/core";
import Loading from "./Loading";
import { actions as userbaseActions } from "../store/userbase";
import Roles from "../Roles";

const styles = {
  table: {
    marginTop: 24,
  },
};

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    paddingRight: 4,
  },
  body: {
    paddingRight: 4,
  },
}))(TableCell);

class UserList extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {};

  state = {};

  rehydrateState() {
    this.props.getUsers({ query: { organization: this.props.organizationId } });
  }

  componentDidMount() {
    this.rehydrateState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isDeletingUser) {
      this.rehydrateState();
    }
  }

  render() {
    const { classes } = this.props;

    if (this.props.isGettingUsers) {
      return <Loading text="Loading Userbase..." />;
    }

    if (this.props.isDeletingUser) {
      return <Loading text="Userbase is Updating..." />;
    }

    if (!this.props.users || this.props.users.length < 1) {
      return (
        <div className={this.props.className}>
          <Typography variant="h5" gutterBottom>
            Userbase
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            No Users to List
          </Typography>
        </div>
      );
    }

    return (
      <div>
        <Typography color="secondary" variant="h5" gutterBottom>
          Userbase
        </Typography>
        <Typography variant="body2" gutterBottom>
          Lists all the members in your organization.
        </Typography>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <CustomTableCell>Name</CustomTableCell>
              <CustomTableCell>Email</CustomTableCell>
              <CustomTableCell>Customer Role</CustomTableCell>
              <CustomTableCell>Actions</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.users.map(user => {
              return (
                <TableRow key={user._id}>
                  <CustomTableCell>{user.name}</CustomTableCell>
                  <CustomTableCell>{user.email}</CustomTableCell>
                  <CustomTableCell>
                    {Roles.getRoleById(user.customerRole).name}
                  </CustomTableCell>
                  <CustomTableCell>
                    {user._id !== this.props.userId &&
                      Roles.getRoleById(this.props.authUser.customerRole)
                        .canManage && (
                        <Button onClick={() => this.props.deleteUser(user._id)}>
                          Remove
                        </Button>
                      )}
                  </CustomTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.userbase.users,
    isGettingUsers: state.userbase.isGettingUsers,
    isDeletingUser: state.userbase.isDeletingUser,

    authUser: state.auth.user,
    userId: state.auth.userId,
    organizationId: state.auth.organizationId,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUsers: userbaseActions.getUsers,
      deleteUser: userbaseActions.deleteUser,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UserList));
