import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Typography } from "@material-ui/core";
import ManageOrganization from "../modules/ManageOrganization";

class Organization extends Component {
  render() {
    return (
      <Fragment>
        <Typography variant="h4" gutterBottom>
          Organization Management
        </Typography>
        <ManageOrganization />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Organization);
