import qs from "qs";
import emailRegex from "email-regex";
import { parsePhoneNumber as phoneNumberParser } from "libphonenumber-js";

export default class Utils {
  static parse(string) {
    return qs.parse(string, { ignoreQueryPrefix: true });
  }

  static stringify(object) {
    return qs.stringify(object);
  }

  static isEmailValid({ email = null } = {}) {
    return emailRegex({ exact: true }).test(email);
  }

  // Returns number in the format: "+12065554321"
  static parsePhoneNumber({ number = null } = {}) {
    try {
      return phoneNumberParser(number).number;
    } catch (error) {
      return false;
    }
  }

  static isPhoneNumberValid({ number = null } = {}) {
    try {
      return phoneNumberParser(number).isValid();
    } catch (error) {
      return false;
    }
  }
}
