import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Paper } from "@material-ui/core";
import { instancesActions } from "../../store/actions";

import Loading from "../Loading";
import CustomerInformation from "./Pages/CustomerInformation";
import OrderInformation from "./Pages/OrderInformation";
import CarrierList from "./Pages/CarrierList";
import LiveReport from "./Pages/LiveReport";
import Messaging from "./Pages/Messaging";
import Review from "./Pages/Review";

const styles = theme => ({
  root: {
    padding: 24,
  },
  section: {
    marginBottom: 18,
  },
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  description: {
    marginBottom: 24,
  },
});

class Page extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired,
    workflowId: PropTypes.string.isRequired,
    instanceId: PropTypes.string.isRequired,
    pageId: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.props.getInstance(this.props.instanceId);
  }

  render() {
    const { classes } = this.props;

    if (!this.props.pageId) {
      return <Typography gutterBottom>Page ID is required.</Typography>;
    }

    if (this.props.isGettingInstance) {
      return <Loading text="Loading Instance..." variant="h5" />;
    }

    if (this.props.isUpdatingInstance) {
      return <Loading text="Updating Instance..." variant="h5" />;
    }

    if (!this.props.instance) {
      return <Typography gutterBottom>Instance does not exist.</Typography>;
    }

    const PAGE = this.props.instance.pages.find(
      page => page.id === this.props.pageId
    );

    if (!PAGE) {
      return <Typography gutterBottom>Page does not exist.</Typography>;
    }

    return (
      <Fragment>
        <Paper className={classes.root}>
          <Typography variant="h4" gutterBottom>
            {PAGE.name}
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className={classes.description}
          >
            {PAGE.description}
          </Typography>
          {PAGE.id === "customer-information" && (
            <CustomerInformation
              nextURL={`/workflows/${this.props.workflowId}/${
                this.props.instanceId
              }/dapp/order-information`}
            />
          )}
          {PAGE.id === "order-information" && (
            <OrderInformation
              nextURL={`/workflows/${this.props.workflowId}/${
                this.props.instanceId
              }/dapp/carrier-list`}
            />
          )}
          {PAGE.id === "carrier-list" && (
            <CarrierList
              nextURL={`/workflows/${this.props.workflowId}/${
                this.props.instanceId
              }/dapp/review`}
            />
          )}
          {PAGE.id === "review" && (
            <Review
              nextURL={`/workflows/${this.props.workflowId}/${
                this.props.instanceId
              }/dapp/live-ledger-report`}
            />
          )}
          {PAGE.id === "live-ledger-report" && (
            <LiveReport
              nextURL={`/workflows/${this.props.workflowId}/${
                this.props.instanceId
              }/dapp/sms-confirmation`}
              prevURL={`/workflows/${this.props.workflowId}/${
                this.props.instanceId
              }/dapp/review`}
            />
          )}
          {PAGE.id === "sms-confirmation" && (
            <Messaging
              nextURL={`/workflows/${this.props.workflowId}/${
                this.props.instanceId
              }/dapp/live-ledger-report`}
            />
          )}
        </Paper>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  instance: state.instances.instance,
  isGettingInstance: state.instances.isGettingInstance,
  isUpdatingInstance: state.instances.isUpdatingInstance,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getInstance: instancesActions.getInstance,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Page));
