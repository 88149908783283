import types from "./types";

const INITIAL = {
  workflow: null,
  workflows: null,
  isGettingWorkflow: false,
  isGettingWorkflows: false,
};

export const reducer = (state = INITIAL, action) => {
  switch (action.type) {
    case types.GET_WORKFLOW_REQUEST:
      return {
        ...state,
        isGettingWorkflow: true,
      };
    case types.GET_WORKFLOW_SUCCESS:
      return {
        ...state,
        workflow: action.payload,
        isGettingWorkflow: false,
      };
    case types.GET_WORKFLOW_FAILURE:
      return {
        ...state,
        workflow: null,
        isGettingWorkflow: false,
      };

    case types.GET_WORKFLOWS_REQUEST:
      return {
        ...state,
        isGettingWorkflows: true,
      };
    case types.GET_WORKFLOWS_SUCCESS:
      return {
        ...state,
        workflows: action.payload,
        isGettingWorkflows: false,
      };
    case types.GET_WORKFLOWS_FAILURE:
      return {
        ...state,
        workflows: null,
        isGettingWorkflows: false,
      };

    default:
      return state;
  }
};
