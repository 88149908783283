import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  TextField,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { instancesActions } from "../../../store/actions";

const styles = {
  root: {},
  section: {
    marginTop: 30,
  },
  button: {
    margin: "20px auto 20px",
  },
};

class CarrierList extends Component {
  constructor(props) {
    super(props);

    const filler = confirmations =>
      confirmations
        .map(
          (confirmation, i) =>
            confirmation.message
              ? confirmation
              : {
                  ...confirmation,
                  message: `${props.instance.organizationName} Message - Re: ${
                    props.instance.name
                  }. Please confirm Cargo ${
                    confirmation.label
                  }. To confirm, reply with the following code: ${
                    props.instance._id
                  }-${i}`,
                }
        )
        .map(
          (confirmation, i) =>
            confirmation.postGraceTime
              ? confirmation
              : {
                  ...confirmation,
                  postGraceTime: moment(confirmation.preGraceTime)
                    .add(props.instance.gracePeriod, "hours")
                    .format(),
                }
        );

    this.state = {
      carriers: props.data.carriers,
      confirmations: filler(props.data.confirmations),
      gracePeriod: props.data.gracePeriod,

      gracePeriodOptions: [
        {
          value: 1,
          label: "1 Hour",
        },
        {
          value: 2,
          label: "2 Hours",
        },
        {
          value: 3,
          label: "3 Hours",
        },
        {
          value: 4,
          label: "4 Hours",
        },
      ],
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.gracePeriod()}
        {this.list()}
        {!this.props.data.hasInitiated && this.button()}
        {this.errors()}
      </div>
    );
  }

  gracePeriod() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Alert Buffer
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          How much time should pass before a confirmation is considered late? If
          there is no confirmation after the grace period, then the confirmation
          is marked as late and the Order Contact and Customer Contact are
          notified.
        </Typography>
        <FormControl className={classes.formControl}>
          <Select
            value={this.state.gracePeriod}
            onChange={event => {
              // Update every confirmation's postGraceTime
              const newConfirmations = this.state.confirmations.map(
                confirmation => {
                  return {
                    ...confirmation,
                    postGraceTime: moment(confirmation.preGraceTime)
                      .add(event.target.value, "hours")
                      .format(),
                  };
                }
              );
              this.setState({
                gracePeriod: event.target.value,
                confirmations: newConfirmations,
              });
            }}
          >
            {this.state.gracePeriodOptions.map((option, i) => (
              <MenuItem value={option.value} key={i}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Fragment>
    );
  }

  list() {
    const { classes } = this.props;

    return (
      <Fragment>
        {this.state.carriers.map((carrier, i) => {
          return (
            <div key={i} className={classes.section}>
              <Typography variant="h4" gutterBottom>
                Carrier {i + 1}
              </Typography>
              <Grid container wrap="wrap" spacing={32}>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography variant="h6" color="textPrimary">
                    Contact Details
                  </Typography>
                  <TextField
                    key="organization"
                    id="organization"
                    label="Organization"
                    type="text"
                    fullWidth
                    margin="normal"
                    defaultValue={carrier.organization}
                    onChange={event => {
                      const updatedCarriers = this.state.carriers;
                      updatedCarriers[i].organization = event.target.value;
                      this.setState({
                        carriers: updatedCarriers,
                      });
                    }}
                  />
                  <TextField
                    key="contactName"
                    id="contactName"
                    label="Contact Name"
                    type="text"
                    fullWidth
                    margin="normal"
                    defaultValue={carrier.name}
                    onChange={event => {
                      const updatedCarriers = this.state.carriers;
                      updatedCarriers[i].name = event.target.value;
                      this.setState({
                        carriers: updatedCarriers,
                      });
                    }}
                  />
                  <TextField
                    key="contactPhone"
                    id="contactPhone"
                    label="Contact Phone"
                    type="text"
                    fullWidth
                    margin="normal"
                    helperText="Format phone number as '+1 xxx xxx xxxx'"
                    defaultValue={carrier.phone}
                    onChange={event => {
                      const updatedCarriers = this.state.carriers;
                      updatedCarriers[i].phone = event.target.value;
                      this.setState({
                        carriers: updatedCarriers,
                      });
                    }}
                  />
                  <TextField
                    key="contactEmail"
                    id="contactEmail"
                    label="Contact Email"
                    type="text"
                    fullWidth
                    margin="normal"
                    defaultValue={carrier.email}
                    onChange={event => {
                      const updatedCarriers = this.state.carriers;
                      updatedCarriers[i].email = event.target.value;
                      this.setState({
                        carriers: updatedCarriers,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="h6" color="textPrimary">
                    Scheduled Message
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    When to message carrier for confirmation.
                  </Typography>
                  {this.state.confirmations
                    .filter(confirmation => confirmation.carrierId === i)
                    .map((confirmation, j) => {
                      return (
                        <TextField
                          key={"preGraceTime-carrier" + i + "confirmation" + j}
                          id={"preGraceTime-carrier" + i + "confirmation" + j}
                          label={confirmation.label}
                          type="text"
                          fullWidth
                          margin="normal"
                          defaultValue={
                            confirmation.preGraceTime || moment().format()
                          }
                          onChange={event => {
                            const updatedConfirmations = this.state.confirmations.map(
                              c => {
                                if (
                                  c.carrierId === i &&
                                  c.label === confirmation.label
                                ) {
                                  c.preGraceTime = moment(
                                    event.target.value
                                  ).format();
                                  c.postGraceTime = moment(event.target.value)
                                    .add(this.state.gracePeriod, "hours")
                                    .format();
                                }
                                return c;
                              }
                            );
                            this.setState({
                              confirmations: updatedConfirmations,
                            });
                          }}
                        />
                      );
                    })}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="h6" color="textPrimary">
                    Alert Time
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    When grace period ends. If unconfirmed, Order and Customer
                    contacts are notified.
                  </Typography>
                  {this.state.confirmations
                    .filter(confirmation => confirmation.carrierId === i)
                    .map((confirmation, j) => {
                      return (
                        <TextField
                          disabled
                          key={"postGraceTime-carrier" + i + "confirmation" + j}
                          id={"postGraceTime-carrier" + i + "confirmation" + j}
                          label={confirmation.label}
                          type="text"
                          fullWidth
                          margin="normal"
                          value={confirmation.postGraceTime || ""}
                        />
                      );
                    })}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" color="textPrimary">
                    Scheduled Messages
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Messages to send to carrier for confirmation.
                  </Typography>
                  {this.state.confirmations
                    .map((confirmation, j) => ({ ...confirmation, key: j }))
                    .filter(confirmation => confirmation.carrierId === i)
                    .map((confirmation, j) => {
                      return (
                        <TextField
                          multiline
                          key={"confirmationRequest" + j}
                          id={"confirmationRequest" + j}
                          label={confirmation.label}
                          type="text"
                          fullWidth
                          margin="normal"
                          value={confirmation.message}
                          onChange={event => {
                            const updatedConfirmations = this.state.confirmations.map(
                              c => {
                                if (
                                  c.carrierId === i &&
                                  c.label === confirmation.label
                                ) {
                                  c.message = event.target.value;
                                }
                                return c;
                              }
                            );
                            this.setState({
                              confirmations: updatedConfirmations,
                            });
                          }}
                        />
                      );
                    })}
                </Grid>
              </Grid>
            </div>
          );
        })}
      </Fragment>
    );
  }

  isFormInvalid() {
    if (
      this.state.carriers.filter(
        carrier =>
          !carrier.organization ||
          !carrier.name ||
          !carrier.phone ||
          !carrier.email
      ).length > 0
    ) {
      return "Cannot save: Form is incomplete";
    }

    if (
      this.state.confirmations.filter(
        confirmation =>
          !confirmation.preGraceTime ||
          !confirmation.postGraceTime ||
          !confirmation.message
      ).length > 0
    ) {
      return "Cannot save: Form is incomplete";
    }

    if (!this.state.gracePeriod) {
      return "Cannot save: Grace period is not selected";
    }

    return false;
  }

  errors() {
    return (
      <Typography color="secondary" variant="body1">
        {this.isFormInvalid()}
      </Typography>
    );
  }

  button() {
    const { classes } = this.props;

    const fields = {
      data: {
        ...this.props.instance.data,
        carriers: this.state.carriers,
        confirmations: this.state.confirmations,
        gracePeriod: this.state.gracePeriod,
        valid: {
          ...this.props.data.valid,
          carriers: true,
          confirmations: true,
        },
      },
    };

    return (
      <Button
        disabled={!!this.isFormInvalid()}
        className={classes.button}
        variant="contained"
        color="primary"
        component={Link}
        to={this.props.nextURL}
        onClick={() => {
          this.props.updateInstance({
            fields: fields,
            id: this.props.instance._id,
          });
        }}
      >
        Save
      </Button>
    );
  }
}

CarrierList.propTypes = {
  classes: PropTypes.object.isRequired,
  nextURL: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    instance: state.instances.instance,
    data: state.instances.instance.data,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateInstance: instancesActions.updateInstance,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CarrierList));
