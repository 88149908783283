import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { instancesActions, messagesActions } from "../../../store/actions";

const styles = {
  form: {
    maxWidth: 400,
  },
  button: {
    marginTop: 20,
    marginBottom: 10,
  },
};

class Review extends Component {
  render() {
    return (
      <Fragment>
        <Typography variant="body1" gutterBottom>
          Ensure that all data submitted is correct before proceeding. Once you
          click "Begin Live Ledger Report", the data you have entered in
          previous pages cannot be altered and will be read-only.
        </Typography>
        {this.errors()}
        {this.button()}
      </Fragment>
    );
  }

  errors() {
    const { customer, order, carriers, confirmations } = this.props.data.valid;

    return (
      <Fragment>
        <Typography color="secondary" variant="body2">
          {!this.isEverythingValid() &&
            "Errors were found in the following data fields:"}
        </Typography>
        {!customer && (
          <Typography color="secondary" variant="body2">
            - Customer Contact Data
          </Typography>
        )}
        {!order && (
          <Typography color="secondary" variant="body2">
            - Order Data
          </Typography>
        )}
        {(!carriers || !confirmations) && (
          <Typography color="secondary" variant="body2">
            - Carrier List Data
          </Typography>
        )}
      </Fragment>
    );
  }

  isEverythingValid() {
    const { customer, order, carriers, confirmations } = this.props.data.valid;

    return customer && order && carriers && confirmations;
  }

  button() {
    const { classes } = this.props;

    const fields = {
      data: {
        ...this.props.instance.data,
        hasInitiated: true,
      },
    };

    const carrierMessages = this.props.data.confirmations.map(
      (confirmation, i) => {
        return {
          message: confirmation.message,
          to: this.props.data.carriers[confirmation.carrierId].phone,
          timeToSend: confirmation.preGraceTime,

          instanceId: this.props.instance._id,
          confirmationId: `${i}`,
        };
      }
    );

    const customerAlertMessages = this.props.data.confirmations.map(
      (confirmation, i) => {
        return {
          message: `ALERT: ${this.props.instance.name}. Cargo ${
            confirmation.label
          } has not been confirmed by ${
            this.props.data.carriers[confirmation.carrierId].organization
          } (Deadline: ${confirmation.postGraceTime}).`,
          to: this.props.data.customer.phone,
          timeToSend: confirmation.postGraceTime,

          instanceId: this.props.instance._id,
          confirmationId: `${i}`,
        };
      }
    );

    const orderAlertMessages = this.props.data.confirmations.map(
      (confirmation, i) => {
        return {
          message: `ALERT: ${this.props.instance.name}. Cargo ${
            confirmation.label
          } has not been confirmed by ${
            this.props.data.carriers[confirmation.carrierId].organization
          } (Deadline: ${confirmation.postGraceTime}).`,
          to: this.props.data.order.contact.phone,
          timeToSend: confirmation.postGraceTime,

          instanceId: this.props.instance._id,
          confirmationId: `${i}`,
        };
      }
    );

    return (
      <Button
        disabled={!this.isEverythingValid()}
        className={classes.button}
        variant="contained"
        color="primary"
        component={Link}
        to={this.props.nextURL}
        onClick={() => {
          this.props.updateInstance({
            fields: fields,
            id: this.props.instance._id,
          });
          this.props.createMessages([
            ...carrierMessages,
            ...customerAlertMessages,
            ...orderAlertMessages,
          ]);
        }}
      >
        Begin Live Ledger Report
      </Button>
    );
  }
}

Review.propTypes = {
  classes: PropTypes.object.isRequired,
  nextURL: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    instance: state.instances.instance,
    data: state.instances.instance.data,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateInstance: instancesActions.updateInstance,
      createMessages: messagesActions.createMessages,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Review));
