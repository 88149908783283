import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Row from "./Row";
import Loading from "./Loading";
import { actions as authActions } from "../store/auth";

const styles = {
  section: {
    marginTop: 24,
    marginBottom: 24,
  },
};

class ManageAccount extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.getUser(this.props.userId);
  }

  render() {
    const { classes } = this.props;

    if (this.props.isGettingUser) {
      return <Loading text="Loading User Account..." variant="h5" />;
    }

    if (!this.props.user) {
      return <Typography gutterBottom>User does not exist.</Typography>;
    }

    return (
      <Fragment>
        <div className={classes.section}>
          <Typography variant="h5" color="secondary" gutterBottom>
            Details and Settings
          </Typography>
          <Row
            label="Full Name"
            value={this.props.user.name}
            action={newValue =>
              this.props.updateUser({
                fields: { name: newValue },
                id: this.props.userId,
              })
            }
          />
          <Row
            label="Email"
            value={this.props.user.email}
            action={newValue =>
              this.props.updateUser({
                fields: { email: newValue },
                id: this.props.userId,
              })
            }
          />
          <Row
            label="Phone Number"
            value={this.props.user.phone}
            action={newValue =>
              this.props.updateUser({
                fields: { phone: newValue },
                id: this.props.userId,
              })
            }
          />
        </div>
        <div className={classes.section}>
          <Typography variant="h5" color="secondary" gutterBottom>
            Upcoming Updates
          </Typography>
          <Typography gutterBottom>Update Notifications</Typography>
          <Typography gutterBottom>Update Password</Typography>
          <Typography gutterBottom>Delete or Disable Account</Typography>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userId: state.auth.userId,
  user: state.auth.user,
  isGettingUser: state.auth.isGettingUser,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUser: authActions.getUser,
      updateUser: authActions.updateUser,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ManageAccount));
