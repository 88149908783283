import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button, TextField, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { instancesActions } from "../../../store/actions";

const styles = {
  form: {
    maxWidth: 400,
  },
  button: {
    marginTop: 20,
    marginBottom: 10,
  },
};

class CustomerInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organization: props.data.customer.organization,
      phone: props.data.customer.phone,
      email: props.data.customer.email,
    };
  }

  form() {
    const { classes } = this.props;

    return (
      <div className={classes.form}>
        <Typography variant="h6" color="secondary">
          Contact Details
        </Typography>
        <TextField
          key="organization"
          id="organization"
          label="Organization"
          autoComplete="organization"
          type="text"
          fullWidth
          margin="normal"
          defaultValue={this.state.organization}
          onChange={event => {
            this.setState({ organization: event.target.value });
          }}
        />
        <TextField
          key="phone"
          id="phone"
          label="Phone"
          autoComplete="phone"
          type="text"
          fullWidth
          margin="normal"
          helperText="Format phone number as '+1 xxx xxx xxxx'"
          defaultValue={this.state.phone}
          onChange={event => {
            this.setState({ phone: event.target.value });
          }}
        />
        <TextField
          key="email"
          id="email"
          label="Email"
          autoComplete="email"
          type="text"
          fullWidth
          margin="normal"
          defaultValue={this.state.email}
          onChange={event => {
            this.setState({ email: event.target.value });
          }}
        />
      </div>
    );
  }

  isFormInvalid() {
    if (!this.state.organization || !this.state.phone || !this.state.email) {
      return "Cannot save: Form is incomplete";
    }

    return false;
  }

  errors() {
    return (
      <Typography color="secondary" variant="body1">
        {this.isFormInvalid()}
      </Typography>
    );
  }

  button() {
    const { classes } = this.props;

    const fields = {
      data: {
        ...this.props.instance.data,
        customer: {
          organization: this.state.organization,
          phone: this.state.phone,
          email: this.state.email,
        },
        valid: {
          ...this.props.data.valid,
          customer: true,
        },
      },
    };

    return (
      <Button
        disabled={!!this.isFormInvalid()}
        className={classes.button}
        variant="contained"
        color="primary"
        component={Link}
        to={this.props.nextURL}
        onClick={() =>
          this.props.updateInstance({
            fields: fields,
            id: this.props.instance._id,
          })
        }
      >
        Save
      </Button>
    );
  }

  render() {
    return (
      <Fragment>
        {this.form()}
        {!this.props.data.hasInitiated && this.button()}
        {this.errors()}
      </Fragment>
    );
  }
}

CustomerInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  nextURL: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    instance: state.instances.instance,
    data: state.instances.instance.data,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateInstance: instancesActions.updateInstance,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CustomerInformation));
