import React, { Component } from "react";
import PropTypes from "prop-types";
import { TextField, Grid, Button } from "@material-ui/core";

class Row extends Component {
  static protoTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    action: PropTypes.func,
  };

  static defaultProps = {
    value: "",
    action: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      editMode: false,
    };
  }

  render() {
    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm={6}>
          <TextField
            label={this.props.label}
            value={this.state.value}
            margin="normal"
            fullWidth
            onChange={event => this.setState({ value: event.target.value })}
            disabled={!this.state.editMode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {!this.state.editMode && (
            <Button
              variant="text"
              color="secondary"
              onClick={() => this.setState({ editMode: true })}
            >
              Edit
            </Button>
          )}
          {this.state.editMode && (
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                this.props.action(this.state.value);
                this.setState({ editMode: false });
              }}
              disabled={!this.state.value}
            >
              Save
            </Button>
          )}
          {this.state.editMode && (
            <Button
              variant="text"
              color="secondary"
              onClick={() =>
                this.setState({ editMode: false, value: this.props.value })
              }
            >
              Cancel
            </Button>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default Row;
