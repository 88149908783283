import types from "./types";

const INITIAL = {
  userId: null,
  organizationId: null,
  user: null,
  organization: null,
  isDecodingToken: false,
  isGettingUser: false,
  isUpdatingUser: false,
  isGettingOrganization: false,
  isUpdatingOrganization: false,
};

export const reducer = (state = INITIAL, action) => {
  switch (action.type) {
    case types.DECODE_TOKEN_REQUEST:
      return {
        ...state,
        isDecodingToken: true,
      };
    case types.DECODE_TOKEN_SUCCESS:
      return {
        ...state,
        userId: action.payload.userId,
        organizationId: action.payload.organizationId,
        isDecodingToken: false,
      };
    case types.DECODE_TOKEN_FAILURE:
      return {
        ...state,
        userId: null,
        organizationId: null,
        isDecodingToken: false,
      };

    case types.GET_USER_REQUEST:
      return {
        ...state,
        isGettingUser: true,
      };
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isGettingUser: false,
      };
    case types.GET_USER_FAILURE:
      return {
        ...state,
        user: null,
        isGettingUser: false,
      };

    case types.UPDATE_USER_REQUEST:
      return {
        ...state,
        isUpdatingUser: true,
      };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isUpdatingUser: false,
      };
    case types.UPDATE_USER_FAILURE:
      return {
        ...state,
        isUpdatingUser: false,
      };

    case types.GET_ORGANIZATION_REQUEST:
      return {
        ...state,
        isGettingOrganization: true,
      };
    case types.GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organization: action.payload,
        isGettingOrganization: false,
      };
    case types.GET_ORGANIZATION_FAILURE:
      return {
        ...state,
        organization: null,
        isGettingOrganization: false,
      };

    case types.UPDATE_ORGANIZATION_REQUEST:
      return {
        ...state,
        isUpdatingOrganization: true,
      };
    case types.UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organization: action.payload,
        isUpdatingOrganization: false,
      };
    case types.UPDATE_ORGANIZATION_FAILURE:
      return {
        ...state,
        isUpdatingOrganization: false,
      };

    case types.LOGOUT:
      return {
        ...state,
        userId: null,
        organizationId: null,
        user: null,
        organization: null,
      };

    default:
      return state;
  }
};
