import types from "./types";

// Creators
export const actions = {
  open,
  clear,
};

// Implementations
function open({ message = "", variant = "" } = {}) {
  return dispatch => {
    dispatch(success(message, variant));
  };

  function success(message, variant) {
    return { type: types.OPEN_SNACKBAR, message, variant };
  }
}

function clear() {
  return dispatch => {
    dispatch(success());
  };

  function success() {
    return { type: types.CLEAR_SNACKBAR };
  }
}
