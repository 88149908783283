import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Theme from "./modules/Theme";
import AppBar from "./modules/AppBar";
import Loading from "./modules/Loading";
import Snackbar from "./modules/snackbar/Snackbar";
import Marketing from "./views/Marketing";
import Dashboard from "./views/Dashboard";
import DeadEnd from "./views/DeadEnd";
import Drawer from "./modules/Drawer";
import Organization from "./views/Organization";
import Account from "./views/Account";
import Workflow from "./views/Workflow";
import InstanceDApp from "./views/InstanceDApp";
import { actions as authActions } from "./store/auth";
import Utils from "./Utils";
import InstanceManagement from "./views/InstanceManagement";
import InstanceCreation from "./views/InstanceCreation";

const styles = theme => ({
  root: {
    display: "flex",
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: Theme.DrawerWidth,
  },
  loading: {
    padding: theme.spacing.unit * 3,
  },
  userContent: {
    flexGrow: 1,
    maxWidth: theme.breakpoints.values.md,
    margin: "0 auto",
    padding: theme.spacing.unit * 3,
  },
  marketingContent: {
    flexGrow: 1,
  },
});

class App extends Component {
  componentDidMount() {
    const parsedURL = Utils.parse(this.props.router.location.search);
    this.props.decodeToken({ token: parsedURL.token });
  }

  render() {
    const { classes } = this.props;

    const showLoading = this.props.isDecodingToken;
    const showMarketing =
      !showLoading && (!this.props.userId || !this.props.organizationId);
    const showDashboard =
      !showLoading && (this.props.userId && this.props.organizationId);

    return (
      <Theme>
        <div className={classes.root}>
          <Snackbar />
          <AppBar />
          {showLoading && (
            <main className={classes.loading}>
              <Loading text="Authenticating User..." />
            </main>
          )}
          {showMarketing && (
            <main className={classes.marketingContent}>
              <div className={classes.toolbar} />
              <Switch>
                <Route exact path="/" component={Marketing} />
                <Route component={DeadEnd} />
              </Switch>
            </main>
          )}
          {showDashboard && (
            <Fragment>
              <Drawer />
              <main className={classes.userContent}>
                <div className={classes.toolbar} />
                <Switch>
                  {/* HOME */}
                  <Route exact path="/" component={Dashboard} />
                  {/* WORKFLOWS AND THEIR INSTANCES */}
                  <Redirect exact path="/workflows" to="/" />
                  <Route
                    exact
                    path="/workflows/:workflowId"
                    component={Workflow}
                  />
                  {/* SINGLE INSTANCE */}
                  <Route
                    exact
                    path="/workflows/:workflowId/create"
                    component={InstanceCreation}
                  />
                  <Route
                    exact
                    path="/workflows/:workflowId/:instanceId/manage"
                    component={InstanceManagement}
                  />
                  <Route
                    exact
                    path="/workflows/:workflowId/:instanceId/dapp/:pageId"
                    component={InstanceDApp}
                  />
                  {/* SETTINGS */}
                  <Route path="/organization" component={Organization} />
                  <Route path="/account" component={Account} />
                  {/* PAGE NOT FOUND */}
                  <Route component={DeadEnd} />
                </Switch>
              </main>
            </Fragment>
          )}
        </div>
      </Theme>
    );
  }
}

const mapStateToProps = state => ({
  isDecodingToken: state.auth.isDecodingToken,
  userId: state.auth.userId,
  organizationId: state.auth.organizationId,
  router: state.router,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      decodeToken: authActions.decodeToken,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(App));
