import types from "./types";

const INITIAL = {
  message: "",
  variant: "",
};

export const reducer = (state = INITIAL, action) => {
  switch (action.type) {
    case types.OPEN_SNACKBAR:
      return {
        ...state,
        message: action.message,
        variant: action.variant,
      };
    case types.CLEAR_SNACKBAR:
      return {
        ...state,
        message: "",
      };

    default:
      return state;
  }
};
