import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { instancesActions } from "../../store/actions";
import Loading from "../Loading";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginBottom: 18,
  },
});

// class ScrollableTabsButtonAuto extends React.Component {
//   static protoTypes = {
//     classes: PropTypes.object.isRequired,
//   };

//   render() {
//     return <Typography gutterBottom>Tabs go here.</Typography>;
//   }
// }

class ScrollableTabsButtonAuto extends React.Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired,
    workflowId: PropTypes.string.isRequired,
    instanceId: PropTypes.string.isRequired,
    pageId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: false,
    };
  }

  componentDidMount() {
    this.props.getInstance(this.props.instanceId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pageId !== this.props.pageId) {
      this.changeValue(this.props.pageId);
    }

    if (prevProps.isGettingInstance) {
      this.changeValue(this.props.pageId);
    }
  }

  handleChange = (event, value) => {
    this.changeValue(value);
  };

  changeValue(value) {
    const VALID_VALUES = this.props.instance.pages
      .filter(page => !page.hidden)
      .map(page => page.id);

    this.setState({ value: VALID_VALUES.includes(value) ? value : false });
  }

  render() {
    const { classes } = this.props;

    if (this.props.isGettingInstance) {
      return <Loading text="Loading Pages..." variant="h5" />;
    }

    if (!this.props.instance) {
      return <Typography gutterBottom>Instance does not exist.</Typography>;
    }

    const PAGES = this.props.instance.pages.filter(page => !page.hidden);

    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
          >
            {PAGES.map((page, i) => (
              <Tab
                label={page.name}
                key={i}
                value={page.id}
                component={Link}
                to={`/workflows/${this.props.workflowId}/${
                  this.props.instanceId
                }/dapp/${page.id}`}
              />
            ))}
          </Tabs>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  instance: state.instances.instance,
  isGettingInstance: state.instances.isGettingInstance,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getInstance: instancesActions.getInstance,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ScrollableTabsButtonAuto));
