import types from "./types";

const INITIAL = {
  user: null,
  users: null,
  isGettingUser: false,
  isGettingUsers: false,
  isUpdatingUser: false,
  isDeletingUser: false,
};

export const reducer = (state = INITIAL, action) => {
  switch (action.type) {
    case types.GET_USER_REQUEST:
      return {
        ...state,
        isGettingUser: true,
      };
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isGettingUser: false,
      };
    case types.GET_USER_FAILURE:
      return {
        ...state,
        user: null,
        isGettingUser: false,
      };

    case types.GET_USERS_REQUEST:
      return {
        ...state,
        isGettingUsers: true,
      };
    case types.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        isGettingUsers: false,
      };
    case types.GET_USERS_FAILURE:
      return {
        ...state,
        users: null,
        isGettingUsers: false,
      };

    case types.UPDATE_USER_REQUEST:
      return {
        ...state,
        isUpdatingUser: true,
      };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isUpdatingUser: false,
      };
    case types.UPDATE_USER_FAILURE:
      return {
        ...state,
        user: null,
        isUpdatingUser: false,
      };

    case types.DELETE_USER_REQUEST:
      return {
        ...state,
        isDeletingUser: true,
      };
    case types.DELETE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isDeletingUser: false,
      };
    case types.DELETE_USER_FAILURE:
      return {
        ...state,
        user: null,
        isDeletingUser: false,
      };

    default:
      return state;
  }
};
