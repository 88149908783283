export default {
  GET_MESSAGES_REQUEST: "GET_MESSAGES_REQUEST",
  GET_MESSAGES_SUCCESS: "GET_MESSAGES_SUCCESS",
  GET_MESSAGES_FAILURE: "GET_MESSAGES_FAILURE",

  GET_MESSAGE_REQUEST: "GET_MESSAGE_REQUEST",
  GET_MESSAGE_SUCCESS: "GET_MESSAGE_SUCCESS",
  GET_MESSAGE_FAILURE: "GET_MESSAGE_FAILURE",

  SEND_MESSAGE_REQUEST: "SEND_MESSAGE_REQUEST",
  SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",
  SEND_MESSAGE_FAILURE: "SEND_MESSAGE_FAILURE",

  DELETE_MESSAGES_REQUEST: "DELETE_MESSAGES_REQUEST",
  DELETE_MESSAGES_SUCCESS: "DELETE_MESSAGES_SUCCESS",
  DELETE_MESSAGES_FAILURE: "DELETE_MESSAGES_FAILURE",

  CREATE_MESSAGE_REQUEST: "CREATE_MESSAGE_REQUEST",
  CREATE_MESSAGE_SUCCESS: "CREATE_MESSAGE_SUCCESS",
  CREATE_MESSAGE_FAILURE: "CREATE_MESSAGE_FAILURE",

  CREATE_MESSAGES_REQUEST: "CREATE_MESSAGES_REQUEST",
  CREATE_MESSAGES_SUCCESS: "CREATE_MESSAGES_SUCCESS",
  CREATE_MESSAGES_FAILURE: "CREATE_MESSAGES_FAILURE",
};
