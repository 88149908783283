import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: "#484848",
      main: "#212121",
      dark: "#000000",
      contrastText: "#fff",
    },
    secondary: {
      light: "#f67a7a",
      main: "#c34a4a",
      dark: "#911414",
      contrastText: "#fff",
    },
  },
  modules: {
    footer: {
      backgroundColor: "#212121",
      color: "#fff",
    },
    logo: {
      color: "#fff",
    },
    common: {
      view: {
        width: 940,
      },
    },
  },
});

class Theme extends Component {
  static DrawerWidth = 240;

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {this.props.children}
      </MuiThemeProvider>
    );
  }
}

export default Theme;
