import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Workflow from "../modules/Workflow";
import Instances from "../modules/Instances";
import { Typography } from "@material-ui/core";

class WorkflowView extends Component {
  render() {
    return (
      <Fragment>
        <Workflow id={this.props.match.params.workflowId} />
        <Typography variant="h4" gutterBottom>
          DApp Instances
        </Typography>
        <Instances workflowId={this.props.match.params.workflowId} />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkflowView);
