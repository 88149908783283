import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { AppBar as MUIAppBar } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Logo from "./Logo";
import Theme from "./Theme";
import { drawerActions, authActions } from "../store/actions";

const styles = theme => ({
  appBarWithDrawer: {
    marginLeft: Theme.DrawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${Theme.DrawerWidth}px)`,
    },
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
});

class AppBar extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (this.props.user) {
      this.props.getOrganization(this.props.user.organization._id);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isGettingUser && this.props.user) {
      this.props.getOrganization(this.props.user.organization._id);
    }
  }

  handleDrawerToggle = () => {
    this.props.toggleDrawer();
  };

  render() {
    const { classes } = this.props;

    const organizationName = this.props.organization
      ? this.props.organization.name
      : null;

    const showMenuButton = this.props.userId;

    return (
      <div className={classes.root}>
        <MUIAppBar
          position="fixed"
          color="primary"
          className={this.props.organization && classes.appBarWithDrawer}
        >
          <Toolbar>
            {showMenuButton && (
              <IconButton
                className={classes.menuButton}
                color="inherit"
                aria-label="Menu"
                onClick={this.handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Logo organization={organizationName} className={classes.grow} />
          </Toolbar>
        </MUIAppBar>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userId: state.auth.userId,
  user: state.auth.user,
  organization: state.auth.organization,
  isGettingUser: state.auth.isGettingUser,
  isGettingOrganization: state.auth.isGettingOrganization,
  isDrawerOpen: state.drawer.isOpen,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOrganization: authActions.getOrganization,
      toggleDrawer: drawerActions.toggle,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AppBar));
