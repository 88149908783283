import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Button, Grid, Divider, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import { messagesActions, instancesActions } from "../../../store/actions";
import ErrorIcon from "@material-ui/icons/Error";
import HelpIcon from "@material-ui/icons/Help";
import DoneIcon from "@material-ui/icons/Done";
import MailIcon from "@material-ui/icons/Mail";

const styles = {
  listItem: {
    padding: 16,
  },
  chip: {
    marginBottom: 8,
  },
  button: {
    marginTop: 8,
    marginRight: 8,
  },
  icon: {
    fontSize: 36,
  },
};

class LiveReport extends Component {
  getStatusText(confirmation) {
    let text;

    if (confirmation.hasConfirmed) {
      text = "Confirmation Received";
      if (
        moment(confirmation.confirmationDate).isAfter(
          confirmation.postGraceTime
        )
      ) {
        text += ", Late";
      } else if (
        moment(confirmation.confirmationDate).isAfter(confirmation.preGraceTime)
      ) {
        text += ", On-Time";
      } else {
        text += ", Early";
      }
    } else if (moment().isBefore(confirmation.preGraceTime)) {
      text = `Sending message ${moment(confirmation.preGraceTime).format(
        "MMM D, LT"
      )}`;
    } else {
      text = "Waiting for Confirmation";
      if (moment().isAfter(confirmation.postGraceTime)) {
        text += ", Late";
      }
    }

    return text;
  }

  getStatusIcon(confirmation) {
    const { classes } = this.props;

    const style = {
      color: this.getStatusColor(confirmation),
    };

    if (confirmation.hasConfirmed) {
      return <DoneIcon className={classes.icon} style={style} />;
    }
    if (moment().isAfter(confirmation.postGraceTime)) {
      return <ErrorIcon className={classes.icon} style={style} />;
    }
    if (moment().isBefore(confirmation.preGraceTime)) {
      return <MailIcon className={classes.icon} style={style} />;
    }
    return <HelpIcon className={classes.icon} style={style} />;
  }

  getStatusColor(confirmation) {
    if (
      confirmation.hasConfirmed &&
      moment().isBefore(confirmation.postGraceTime)
    ) {
      return "#8de231"; // green
    }

    if (moment().isAfter(confirmation.postGraceTime)) {
      return "#ff3a3a"; // red
    }

    if (moment().isAfter(confirmation.preGraceTime)) {
      return "#99ddff"; // blue
    }

    return "#cecece"; // gray
  }

  getStatusBackgroundColor(confirmation) {
    if (confirmation.hasConfirmed) {
      return "#ffffff"; //white
    }

    if (moment().isAfter(confirmation.preGraceTime)) {
      return "#e6f7ff"; // blue
    }

    return "#f9f9f9"; // gray
  }

  render() {
    const { classes } = this.props;

    if (!this.props.data.hasInitiated) {
      return (
        <Fragment>
          <Typography variant="body1" color="secondary" gutterBottom>
            Tracking must be initiated for the Live Ledger Report to be shown.
            To initiate, validate and submit your data.
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            component={Link}
            to={this.props.prevURL}
          >
            Review and Initiate
          </Button>
        </Fragment>
      );
    }

    return (
      <div>
        {this.props.data.confirmations.map((confirmation, i) => (
          <Fragment key={i}>
            <div
              className={classes.listItem}
              style={{
                backgroundColor: this.getStatusBackgroundColor(confirmation),
              }}
            >
              <Grid container wrap="wrap" spacing={8}>
                <Grid item xs={1}>
                  {this.getStatusIcon(confirmation)}
                </Grid>

                <Grid item xs={11} sm={5}>
                  <Typography variant="h6" gutterBottom>
                    Cargo {confirmation.label}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {this.getStatusText(confirmation)}
                  </Typography>
                </Grid>

                <Hidden smUp>
                  <Grid item xs={2} />
                </Hidden>
                <Grid item xs={11} sm={6}>
                  <Typography variant="body1" gutterBottom>
                    {
                      this.props.data.carriers[confirmation.carrierId]
                        .organization
                    }
                    {" / "}
                    {this.props.data.carriers[confirmation.carrierId].name}
                    {" / "}
                    {this.props.data.carriers[confirmation.carrierId].phone}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Expected Before:{" "}
                    {moment(confirmation.postGraceTime).format("lll")}
                  </Typography>
                  {confirmation.confirmationDate && (
                    <Typography variant="body2" gutterBottom>
                      Confirmed On:{" "}
                      {moment(confirmation.confirmationDate).format("lll")}
                    </Typography>
                  )}

                  {!confirmation.hasConfirmed && (
                    <Button
                      variant="outlined"
                      color="default"
                      className={classes.button}
                      size="small"
                      onClick={() => {
                        this.props.updateInstance({
                          fields: {
                            data: {
                              ...this.props.instance.data,
                              messenger: {
                                instanceId: this.props.instance._id,
                                confirmationId: `${i}`,
                                to: this.props.data.carriers[
                                  confirmation.carrierId
                                ].name,
                                organization: this.props.data.carriers[
                                  confirmation.carrierId
                                ].organization,
                                phone: this.props.data.carriers[
                                  confirmation.carrierId
                                ].phone,
                                message: confirmation.message,
                              },
                            },
                          },
                          id: this.props.instance._id,
                        });
                      }}
                      component={Link}
                      to={this.props.nextURL}
                    >
                      Message
                    </Button>
                  )}
                  {!confirmation.hasConfirmed && (
                    <Button
                      variant="outlined"
                      color="default"
                      size="small"
                      className={classes.button}
                      onClick={() => {
                        const updatedConfirmations = this.props.data
                          .confirmations;
                        updatedConfirmations[i] = {
                          ...updatedConfirmations[i],
                          hasConfirmed: true,
                          confirmationDate: moment().format(),
                        };
                        this.props.updateInstance({
                          fields: {
                            data: {
                              ...this.props.instance.data,
                              confirmations: updatedConfirmations,
                            },
                          },
                          id: this.props.instance._id,
                        });
                        this.props.deleteMessages({
                          query: {
                            confirmationId: `${i}`,
                            instanceId: this.props.instance._id,
                          },
                        });
                      }}
                    >
                      Confirm
                    </Button>
                  )}
                </Grid>
                {confirmation.transactionHash && (
                  <Fragment>
                    <Grid item xs={1} />
                    <Grid item xs={11}>
                      <Typography variant="body2">
                        TX: {confirmation.transactionHash}
                      </Typography>
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            </div>
            <Divider />
          </Fragment>
        ))}
      </div>
    );
  }
}

LiveReport.propTypes = {
  classes: PropTypes.object.isRequired,
  nextURL: PropTypes.string.isRequired,
  prevURL: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    instance: state.instances.instance,
    data: state.instances.instance.data,

    auth: state.auth,

    messages: state.messages.messages,
    isGettingMessages: state.messages.isGettingMessages,
    isSendingMessage: state.messages.isSendingMessage,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getMessages: messagesActions.getMessages,
      deleteMessages: messagesActions.deleteMessages,
      updateInstance: instancesActions.updateInstance,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LiveReport));
