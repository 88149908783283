import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Constants from "../Constants";
import Utils from "../Utils";

const LOGIN_URL = `${Constants.BXS_HOMEPAGE_URL}/login?${Utils.stringify({
  ref: Constants.BXS_STUDIO_URL,
  external: true,
  returnToken: true,
})}`;

const INVITE_URL = `${Constants.BXS_HOMEPAGE_URL}/signup`;

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    maxWidth: theme.breakpoints.values.md,
    margin: "0 auto",
    padding: `120px ${theme.spacing.unit * 4}px`,
    [theme.breakpoints.up("md")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  text: {
    color: theme.palette.primary.contrastText,
  },
  button: {
    marginTop: 20,
  },
  requestButton: {
    color: theme.palette.primary.contrastText,
  },
});

function Marketing(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h4" className={classes.text}>
          Welcome to Studio. Create, customize, manage and monitor your
          BLOCKxStudio Smart Contract Templates and Workflows.
        </Typography>
        <Button
          href={LOGIN_URL}
          color="secondary"
          variant="contained"
          size="large"
          classes={{
            root: classes.button,
          }}
        >
          Sign Into Your Studio
        </Button>
        <Button
          href={INVITE_URL}
          variant="text"
          className={classes.requestButton}
          classes={{
            root: classes.button,
          }}
        >
          No Account? Get an Invite to Join
        </Button>
      </div>
    </div>
  );
}

Marketing.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Marketing);
