import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Snackbar as MUISnackbar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import SnackbarContent from "./SnackbarContent";
import { snackbarActions } from "../../store/actions";

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
});

class Snackbar extends React.Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {};

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.props.clear();
  };

  render() {
    const { classes } = this.props;

    return (
      <MUISnackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={!!this.props.message}
        autoHideDuration={3000}
        onClose={this.handleClose}
      >
        <SnackbarContent
          className={classes.margin}
          onClose={this.handleClose}
          variant={this.props.variant}
          message={this.props.message}
        />
      </MUISnackbar>
    );
  }
}

const mapStateToProps = state => {
  return {
    variant: state.snackbar.variant,
    message: state.snackbar.message,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clear: snackbarActions.clear,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Snackbar));
