import types from "./types";

const INITIAL = {
  invite: null,
  invites: null,
  isGettingInvite: false,
  isGettingInvites: false,
  isDeletingInvite: false,
  isSendingInvite: false,
};

export const reducer = (state = INITIAL, action) => {
  switch (action.type) {
    case types.GET_INVITE_REQUEST:
      return {
        ...state,
        isGettingInvite: true,
      };
    case types.GET_INVITE_SUCCESS:
      return {
        ...state,
        invite: action.payload,
        isGettingInvite: false,
      };
    case types.GET_INVITE_FAILURE:
      return {
        ...state,
        invite: null,
        isGettingInvite: false,
      };

    case types.GET_INVITES_REQUEST:
      return {
        ...state,
        isGettingInvites: true,
      };
    case types.GET_INVITES_SUCCESS:
      return {
        ...state,
        invites: action.payload,
        isGettingInvites: false,
      };
    case types.GET_INVITES_FAILURE:
      return {
        ...state,
        invites: null,
        isGettingInvites: false,
      };

    case types.DELETE_INVITE_REQUEST:
      return {
        ...state,
        isDeletingInvite: true,
      };
    case types.DELETE_INVITE_SUCCESS:
      return {
        ...state,
        invite: action.payload,
        isDeletingInvite: false,
      };
    case types.DELETE_INVITE_FAILURE:
      return {
        ...state,
        invite: null,
        isDeletingInvite: false,
      };

    case types.SEND_INVITE_REQUEST:
      return {
        ...state,
        isSendingInvite: true,
      };
    case types.SEND_INVITE_SUCCESS:
      return {
        ...state,
        invite: action.payload,
        isSendingInvite: false,
      };
    case types.SEND_INVITE_FAILURE:
      return {
        ...state,
        invite: null,
        isSendingInvite: false,
      };

    default:
      return state;
  }
};
