import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Loading from "./Loading";
import { actions as invitesActions } from "../store/invites";
import Roles from "../Roles";

const styles = {
  table: {
    marginTop: 16,
  },
  empty: {
    textAlign: "center",
    padding: 24,
  },
};

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    paddingRight: 4,
  },
  body: {
    paddingRight: 4,
  },
}))(TableCell);

class InvitesList extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired,
    filterRoles: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    filterRoles: null,
  };

  rehydrateState() {
    this.props.getInvites({
      query: { organizationId: this.props.organizationId },
    });
  }

  componentDidMount() {
    this.rehydrateState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isDeletingInvite || prevProps.isSendingInvite) {
      this.rehydrateState();
    }
  }

  render() {
    const { classes } = this.props;
    if (this.props.isGettingInvites) {
      return <Loading text="Loading Invites" />;
    }

    return (
      <div className={classes.className}>
        <Typography variant="h5" color="secondary" gutterBottom>
          Pending Invites
        </Typography>
        <Typography variant="body2" gutterBottom>
          This table lists all the invites you have sent that are pending
          response from the user. When they receive the email and use the code
          to create their account, their invite will disappear from this list
          and you will see them as a member in your organization's userbase.
          Deleting an invite from this list will prevent them signing up with
          the code emailed to them.
        </Typography>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <CustomTableCell>Name</CustomTableCell>
              <CustomTableCell>Email</CustomTableCell>
              <CustomTableCell>Role ID</CustomTableCell>
              <CustomTableCell>Role Name</CustomTableCell>
              <CustomTableCell>Code</CustomTableCell>
              <CustomTableCell>Actions</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.invites &&
              this.props.invites
                .filter(invite => this.props.filterRoles.includes(invite.role))
                .map(invite => {
                  return (
                    <TableRow key={invite._id}>
                      <CustomTableCell>{invite.name}</CustomTableCell>
                      <CustomTableCell>{invite.email}</CustomTableCell>
                      <CustomTableCell>{invite.role}</CustomTableCell>
                      <CustomTableCell>
                        {Roles.getRoleById(invite.role).name}
                      </CustomTableCell>
                      <CustomTableCell>{invite.code}</CustomTableCell>
                      <CustomTableCell>
                        <Button
                          onClick={() => this.props.deleteInvite(invite._id)}
                        >
                          Delete
                        </Button>
                      </CustomTableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
        {(!this.props.invites || this.props.invites.length < 1) && (
          <Typography variant="caption" gutterBottom className={classes.empty}>
            ( Empty )
          </Typography>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    invites: state.invites.invites || [],
    isGettingInvites: state.invites.isGettingInvites,
    isDeletingInvite: state.invites.isDeletingInvite,
    isSendingInvite: state.invites.isSendingInvite,

    organizationId: state.auth.organizationId,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getInvites: invitesActions.getInvites,
      deleteInvite: invitesActions.deleteInvite,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InvitesList));
