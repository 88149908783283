import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

const styles = theme => ({
  link: {
    textDecoration: "none",
  },
  text: {
    [theme.breakpoints.up("sm")]: {
      ...theme.typography.h6,
      color: theme.modules.logo.color,
    },
    [theme.breakpoints.down("xs")]: {
      ...theme.typography.h6,
      color: theme.modules.logo.color,
    },
  },
});

function Logo(props) {
  const { classes } = props;

  return (
    <div className={props.className}>
      <Link to="/" className={classes.link}>
        <Typography className={classes.text}>
          {props.organization ? `${props.organization}` : "BLOCKxStudio"}
        </Typography>
      </Link>
    </div>
  );
}

Logo.propTypes = {
  classes: PropTypes.object.isRequired,
  organization: PropTypes.string,
};

Logo.defaultProps = {
  organization: null,
};

export default withStyles(styles)(Logo);
