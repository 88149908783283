import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { workflowsActions } from "../store/actions";
import Loading from "./Loading";

const styles = theme => ({
  root: {
    marginBottom: 16,
  },
});

class Workflow extends Component {
  static protoTypes = {
    id: PropTypes.string.isRequired,
  };

  static defaultProps = {};

  componentDidMount() {
    this.props.getWorkflow(this.props.id);
  }

  render() {
    const { classes } = this.props;

    if (this.props.isGettingWorkflow) {
      return <Loading text="Loading Workflow..." />;
    }

    if (!this.props.workflow) {
      return <Typography>Workflow does not exist.</Typography>;
    }

    return (
      <div className={classes.root}>
        <Typography gutterBottom variant="h4">
          {this.props.workflow.name}
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          {this.props.workflow.description}
        </Typography>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  workflow: state.workflows.workflow,
  isGettingWorkflow: state.workflows.isGettingWorkflow,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getWorkflow: workflowsActions.getWorkflow,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Workflow));
