import { push } from "react-router-redux";
import axios from "axios";
import types from "./types";
import Constants from "../../Constants";
import { actions as snackbarActions } from "../snackbar";

// Creators
export const actions = {
  decodeToken,
  getUser,
  getOrganization,
  updateUser,
  updateOrganization,
  logout,
};

// Implementations
function decodeToken({ token = null } = {}) {
  return dispatch => {
    const tokenToBeDecoded = token || Constants.Cookies.get("token");

    if (!tokenToBeDecoded) {
      return;
    }

    Constants.Cookies.set("token", tokenToBeDecoded, { path: "/" });

    dispatch(request());

    axios({
      method: "get",
      url: "/decode",
      baseURL: Constants.BXS_API_URL,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(
          success({
            userId: res.data.payload._id,
            organizationId: res.data.payload.organization._id,
          })
        );
      })
      .catch(error => {
        dispatch(failure());
        Constants.Cookies.remove("token", { path: "/" });
      });
  };

  function request() {
    return { type: types.DECODE_TOKEN_REQUEST };
  }
  function success(payload) {
    return { type: types.DECODE_TOKEN_SUCCESS, payload };
  }
  function failure() {
    return { type: types.DECODE_TOKEN_FAILURE };
  }
}

function getUser(id) {
  return dispatch => {
    if (!id) {
      return;
    }

    dispatch(request());

    axios({
      method: "get",
      url: `/users/${id}`,
      baseURL: Constants.BXS_API_URL,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
      });
  };

  function request() {
    return { type: types.GET_USER_REQUEST };
  }
  function success(payload) {
    return { type: types.GET_USER_SUCCESS, payload };
  }
  function failure() {
    return { type: types.GET_USER_FAILURE };
  }
}

function updateUser({ fields = null, id = null } = {}) {
  return dispatch => {
    dispatch(request());
    if (!fields || !id) {
      dispatch(failure());
      dispatch(
        snackbarActions.open({
          message: `Action error: Cannot update user. Missing fields or id.`,
          variant: "error",
        })
      );
      return;
    }

    axios({
      method: "put",
      url: `/users/${id}`,
      baseURL: Constants.BXS_API_URL,
      data: fields,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error: Update user request failed.`,
            variant: "error",
          })
        );
      });
  };

  function request() {
    return { type: types.UPDATE_USER_REQUEST };
  }
  function success(payload) {
    return { type: types.UPDATE_USER_SUCCESS, payload };
  }
  function failure() {
    return { type: types.UPDATE_USER_FAILURE };
  }
}

function getOrganization(id) {
  return dispatch => {
    if (!id) {
      return;
    }

    dispatch(request());

    axios({
      method: "get",
      url: `/organizations/${id}`,
      baseURL: Constants.BXS_API_URL,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
      });
  };

  function request() {
    return { type: types.GET_ORGANIZATION_REQUEST };
  }
  function success(payload) {
    return { type: types.GET_ORGANIZATION_SUCCESS, payload };
  }
  function failure() {
    return { type: types.GET_ORGANIZATION_FAILURE };
  }
}

function updateOrganization({ fields = null, id = null } = {}) {
  return dispatch => {
    dispatch(request());
    if (!fields || !id) {
      dispatch(failure());
      dispatch(
        snackbarActions.open({
          message: `Action error: Cannot update organization. Missing fields or id.`,
          variant: "error",
        })
      );
      return;
    }

    axios({
      method: "put",
      url: `/organizations/${id}`,
      baseURL: Constants.BXS_API_URL,
      data: fields,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error: Update organization request failed.`,
            variant: "error",
          })
        );
      });
  };

  function request() {
    return { type: types.UPDATE_ORGANIZATION_REQUEST };
  }
  function success(payload) {
    return { type: types.UPDATE_ORGANIZATION_SUCCESS, payload };
  }
  function failure() {
    return { type: types.UPDATE_ORGANIZATION_FAILURE };
  }
}

function logout({ redirectTo = null } = {}) {
  Constants.Cookies.remove("token", { path: "/" });

  return dispatch => {
    dispatch(success());
    if (redirectTo) {
      dispatch(push(redirectTo));
    }
    dispatch(
      snackbarActions.open({
        message: "Successfully logged out!",
        variant: "success",
      })
    );
  };

  function success() {
    return { type: types.LOGOUT };
  }
}
