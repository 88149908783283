import { combineReducers } from "redux";
import { routerReducer as router } from "react-router-redux";
import { reducer as auth } from "./auth";
import { reducer as snackbar } from "./snackbar";
import { reducer as drawer } from "./drawer";
import { reducer as workflows } from "./workflows";
import { reducer as instances } from "./instances";
import { reducer as messages } from "./messages";
import { reducer as invites } from "./invites";
import { reducer as userbase } from "./userbase";

const RootReducer = combineReducers({
  router,
  auth,
  snackbar,
  drawer,
  workflows,
  instances,
  messages,
  invites,
  userbase,
});

export default RootReducer;
