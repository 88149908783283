export default {
  GET_INSTANCES_REQUEST: "GET_INSTANCES_REQUEST",
  GET_INSTANCES_SUCCESS: "GET_INSTANCES_SUCCESS",
  GET_INSTANCES_FAILURE: "GET_INSTANCES_FAILURE",

  GET_INSTANCE_REQUEST: "GET_INSTANCE_REQUEST",
  GET_INSTANCE_SUCCESS: "GET_INSTANCE_SUCCESS",
  GET_INSTANCE_FAILURE: "GET_INSTANCE_FAILURE",

  UPDATE_INSTANCE_REQUEST: "UPDATE_INSTANCE_REQUEST",
  UPDATE_INSTANCE_SUCCESS: "UPDATE_INSTANCE_SUCCESS",
  UPDATE_INSTANCE_FAILURE: "UPDATE_INSTANCE_FAILURE",

  DELETE_INSTANCE_REQUEST: "DELETE_INSTANCE_REQUEST",
  DELETE_INSTANCE_SUCCESS: "DELETE_INSTANCE_SUCCESS",
  DELETE_INSTANCE_FAILURE: "DELETE_INSTANCE_FAILURE",

  CREATE_INSTANCE_REQUEST: "CREATE_INSTANCE_REQUEST",
  CREATE_INSTANCE_SUCCESS: "CREATE_INSTANCE_SUCCESS",
  CREATE_INSTANCE_FAILURE: "CREATE_INSTANCE_FAILURE",
};
