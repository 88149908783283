import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Typography } from "@material-ui/core";
import ManageAccount from "../modules/ManageAccount";

class Account extends Component {
  render() {
    return (
      <Fragment>
        <Typography variant="h4">Account Management</Typography>
        <ManageAccount />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Account);
