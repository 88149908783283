import types from "./types";

// Creators
export const actions = {
  open,
  close,
  toggle,
};

// Implementations
function open() {
  return dispatch => {
    dispatch(success());
  };

  function success() {
    return { type: types.OPEN_DRAWER };
  }
}

function close() {
  return dispatch => {
    dispatch(success());
  };

  function success() {
    return { type: types.CLOSE_DRAWER };
  }
}

function toggle() {
  return dispatch => {
    dispatch(success());
  };

  function success() {
    return { type: types.TOGGLE_DRAWER };
  }
}
