import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Overview from "../modules/Instance/Overview";

class InstanceManagement extends Component {
  render() {
    return (
      <Fragment>
        <Overview
          workflowId={this.props.match.params.workflowId}
          instanceId={this.props.match.params.instanceId}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstanceManagement);
