export default class Roles {
  static LIST = [
    {
      id: "bxs-admin",
      name: "BXS Admin",
      shortName: "Admin",
      canInvite: ["bxs-admin", "bxs-developer", "customer-owner"],
      canManage: true,
      description: "",
      isInternal: true,
    },
    {
      id: "bxs-developer",
      name: "BXS Developer",
      shortName: "Developer",
      canInvite: null,
      canManage: false,
      description: "",
      isInternal: true,
    },
    {
      id: "customer-owner",
      name: "Customer Owner",
      shortName: "Owner",
      canInvite: ["customer-admin", "customer-developer", "customer-producer"],
      canManage: true,
      description:
        "Owner pays for the account. Owner can invite, edit and remove users to the account and assign their roles. An account can only have one owner.",
      isInternal: false,
    },
    {
      id: "customer-admin",
      name: "Customer Admin",
      shortName: "Admin",
      canInvite: ["customer-admin", "customer-developer", "customer-producer"],
      canManage: true,
      description:
        "Administrators have full access to invite, edit and remove users, templates and provision embeds. ",
      isInternal: false,
    },
    {
      id: "customer-developer",
      name: "Customer Developer",
      shortName: "Developer",
      canInvite: null,
      canManage: false,
      description:
        "Developers have access the BLOCKxStudio develop portal to create, edit and publish smart contract templates.",
      isInternal: false,
    },
    {
      id: "customer-producer",
      name: "Customer Producer",
      shortName: "Producer",
      canInvite: null,
      canManage: false,
      description:
        "Producers can browse and select smart contract templates, compose workflows to deploy them into their enterprise systems.",
      isInternal: false,
    },
    {
      id: "customer-consumer",
      name: "Customer Consumer",
      shortName: "Consumer",
      canInvite: null,
      canManage: false,
      description:
        "Consumers have read-only access to a DApp owned by a Customer.",
      isInternal: false,
    },
  ];

  // Expects string
  // Should return an object
  static getRoleById(id) {
    if (!id) {
      return null;
    }
    return Roles.LIST.find(role => role.id === id);
  }

  // Expects an array of strings
  // Should return an array of objects
  static getRolesByIds(ids) {
    if (!ids) {
      return null;
    }
    return ids.map(id => Roles.getRoleById(id));
  }

  // Expects a string
  // Should return a string
  static getNameById(id) {
    if (!id) {
      return null;
    }

    const foundName = Roles.LIST.find(role => role.id === id);

    return !foundName ? null : foundName.name;
  }

  // Should return only customer roles
  static getCustomerRoles() {
    return Roles.LIST.filter(role => !role.isInternal);
  }
}
