import React, { Component } from "react";
import PageNotFound from "../modules/PageNotFound";

class DeadEnd extends Component {
  render() {
    return <PageNotFound />;
  }
}

export default DeadEnd;
