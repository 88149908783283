import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { workflowsActions } from "../store/actions";
import Loading from "./Loading";
import List from "./List";

const styles = theme => ({
  root: {},
});

class Workflows extends Component {
  componentDidMount() {
    this.props.getWorkflows({
      query: { purchasedBy: this.props.organizationId },
    });
  }

  render() {
    const { classes } = this.props;

    if (this.props.isGettingWorkflows) {
      return <Loading text="Loading Workflows..." />;
    }

    if (!this.props.workflows) {
      return <Typography>There are no workflows.</Typography>;
    }

    return (
      <div className={classes.root}>
        <Typography gutterBottom variant="h4">
          Workflows
        </Typography>
        <List
          items={this.props.workflows.map(workflow => ({
            primaryText: workflow.name,
            secondaryText: workflow.description,
            redirectTo: `/workflows/${workflow._id}`,
          }))}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  workflows: state.workflows.workflows,
  isGettingWorkflows: state.workflows.isGettingWorkflows,
  organizationId: state.auth.organizationId,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getWorkflows: workflowsActions.getWorkflows,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Workflows));
