import axios from "axios";
import types from "./types";
import Constants from "../../Constants";
import { actions as snackbarActions } from "../snackbar";
import Utils from "../../Utils";

// Creators
export const actions = {
  getInstances,
  getInstance,
  updateInstance,
  deleteInstance,
  createInstance,
};

// Implementations
function getInstances({ query = null } = {}) {
  return dispatch => {
    dispatch(request());

    const stringifiedQuery = Utils.stringify(query);

    axios({
      method: "get",
      url: `/instances?${stringifiedQuery}`,
      baseURL: Constants.BXS_API_URL,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error: Get instances request failed.`,
            variant: "error",
          })
        );
      });
  };

  function request() {
    return { type: types.GET_INSTANCES_REQUEST };
  }
  function success(payload) {
    return { type: types.GET_INSTANCES_SUCCESS, payload };
  }
  function failure() {
    return { type: types.GET_INSTANCES_FAILURE };
  }
}

function getInstance(id) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "get",
      url: `/instances/${id}`,
      baseURL: Constants.BXS_API_URL,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error: Get instance by id failed.`,
            variant: "error",
          })
        );
      });
  };

  function request() {
    return { type: types.GET_INSTANCE_REQUEST };
  }
  function success(payload) {
    return { type: types.GET_INSTANCE_SUCCESS, payload };
  }
  function failure() {
    return { type: types.GET_INSTANCE_FAILURE };
  }
}

function updateInstance({ fields = null, id = null } = {}) {
  return dispatch => {
    dispatch(request());
    if (!fields || !id) {
      dispatch(failure());
      dispatch(
        snackbarActions.open({
          message: `Action error: Cannot update instance. Missing fields or id.`,
          variant: "error",
        })
      );
      return;
    }

    axios({
      method: "put",
      url: `/instances/${id}`,
      baseURL: Constants.BXS_API_URL,
      data: fields,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error: Update instance request failed.`,
            variant: "error",
          })
        );
      });
  };

  function request() {
    return { type: types.UPDATE_INSTANCE_REQUEST };
  }
  function success(payload) {
    return { type: types.UPDATE_INSTANCE_SUCCESS, payload };
  }
  function failure() {
    return { type: types.UPDATE_INSTANCE_FAILURE };
  }
}

function deleteInstance(id) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "delete",
      url: `/instances/${id}`,
      baseURL: Constants.BXS_API_URL,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error: Delete instance by id failed.`,
            variant: "error",
          })
        );
      });
  };

  function request() {
    return { type: types.DELETE_INSTANCE_REQUEST };
  }
  function success(payload) {
    return { type: types.DELETE_INSTANCE_SUCCESS, payload };
  }
  function failure() {
    return { type: types.DELETE_INSTANCE_FAILURE };
  }
}

function createInstance({ fields = null } = {}) {
  return dispatch => {
    dispatch(request());
    if (!fields) {
      dispatch(failure());
      dispatch(
        snackbarActions.open({
          message: `Action error: Cannot create instance. Missing fields.`,
          variant: "error",
        })
      );
      return;
    }

    axios({
      method: "post",
      url: `/instances`,
      baseURL: Constants.BXS_API_URL,
      data: fields,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error: Create instance request failed.`,
            variant: "error",
          })
        );
      });
  };

  function request() {
    return { type: types.CREATE_INSTANCE_REQUEST };
  }
  function success(payload) {
    return { type: types.CREATE_INSTANCE_SUCCESS, payload };
  }
  function failure() {
    return { type: types.CREATE_INSTANCE_FAILURE };
  }
}
