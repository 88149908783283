import axios from "axios";
import types from "./types";
import Constants from "../../Constants";
import { actions as snackbarActions } from "../snackbar";
import Utils from "../../Utils";

// Creators
export const actions = {
  getMessages,
  getMessage,
  sendMessage,
  deleteMessages,
  createMessage,
  createMessages,
};

// Implementations
function getMessages({ query = null } = {}) {
  return dispatch => {
    dispatch(request());

    const stringifiedQuery = Utils.stringify(query);

    axios({
      method: "get",
      url: `/sms?${stringifiedQuery}`,
      baseURL: Constants.BXS_API_URL,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error: Get messages request failed.`,
            variant: "error",
          })
        );
      });
  };

  function request() {
    return { type: types.GET_MESSAGES_REQUEST };
  }
  function success(payload) {
    return { type: types.GET_MESSAGES_SUCCESS, payload };
  }
  function failure() {
    return { type: types.GET_MESSAGES_FAILURE };
  }
}

function getMessage(id) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "get",
      url: `/sms/${id}`,
      baseURL: Constants.BXS_API_URL,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error: Get message by id failed.`,
            variant: "error",
          })
        );
      });
  };

  function request() {
    return { type: types.GET_MESSAGE_REQUEST };
  }
  function success(payload) {
    return { type: types.GET_MESSAGE_SUCCESS, payload };
  }
  function failure() {
    return { type: types.GET_MESSAGE_FAILURE };
  }
}

function sendMessage(message) {
  return dispatch => {
    dispatch(request());

    if (!message.to) {
      dispatch(failure());
      dispatch(
        snackbarActions.open({
          message: "Action error: Cannot send message. Missing param `to`.",
          variant: "error",
        })
      );
      return;
    }

    axios({
      method: "post",
      url: `/sms/send`,
      baseURL: Constants.BXS_API_URL,
      data: message,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error: Send message request failed.`,
            variant: "error",
          })
        );
      });
  };

  function request() {
    return { type: types.SEND_MESSAGE_REQUEST };
  }
  function success(payload) {
    return { type: types.SEND_MESSAGE_SUCCESS, payload };
  }
  function failure() {
    return { type: types.SEND_MESSAGE_FAILURE };
  }
}

function deleteMessages({ query = null } = {}) {
  return dispatch => {
    dispatch(request());

    if (!query) {
      dispatch(failure());
      return;
    }

    const stringifiedQuery = Utils.stringify(query);

    axios({
      method: "delete",
      url: `/sms?${stringifiedQuery}`,
      baseURL: Constants.BXS_API_URL,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success());
      })
      .catch(error => {
        dispatch(failure());
      });
  };

  function request() {
    return { type: types.DELETE_MESSAGES_REQUEST };
  }
  function success() {
    return { type: types.DELETE_MESSAGES_SUCCESS };
  }
  function failure() {
    return { type: types.DELETE_MESSAGES_FAILURE };
  }
}

function createMessage(message) {
  return dispatch => {
    dispatch(request());

    if (!message.to) {
      dispatch(failure());
      dispatch(
        snackbarActions.open({
          message: "Action error: Cannot create message. Missing param `to`.",
          variant: "error",
        })
      );
      return;
    }

    axios({
      method: "post",
      url: `/sms/messages`,
      baseURL: Constants.BXS_API_URL,
      data: message,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error: Create message request failed.`,
            variant: "error",
          })
        );
      });
  };

  function request() {
    return { type: types.CREATE_MESSAGE_REQUEST };
  }
  function success(payload) {
    return { type: types.CREATE_MESSAGE_SUCCESS, payload };
  }
  function failure() {
    return { type: types.CREATE_MESSAGE_FAILURE };
  }
}

function createMessages(messages) {
  return dispatch => {
    dispatch(request());

    const promises = messages.map(message =>
      axios({
        method: "post",
        url: `/sms/messages`,
        baseURL: Constants.BXS_API_URL,
        data: message,
        headers: { "x-access-token": Constants.Cookies.get("token") },
      })
    );

    axios
      .all(promises)
      .then(responses => {
        const payload = responses.map(response => response.data.payload);
        dispatch(success(payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error: Create message request failed.`,
            variant: "error",
          })
        );
      });
  };

  function request() {
    return { type: types.CREATE_MESSAGES_REQUEST };
  }
  function success(payload) {
    return { type: types.CREATE_MESSAGES_SUCCESS, payload };
  }
  function failure() {
    return { type: types.CREATE_MESSAGES_FAILURE };
  }
}
