import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button, TextField, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import { messagesActions } from "../../../store/actions";

const styles = {
  button: {
    margin: "20px 0px",
  },
};

class Messaging extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: props.data.messenger.message,
      additional: "",
    };
  }

  form() {
    const { classes } = this.props;

    return (
      <div className={classes.form}>
        <TextField
          key="to"
          id="to"
          label="To:"
          autoComplete="to"
          type="text"
          fullWidth
          margin="normal"
          multiline
          value={this.props.data.messenger.to}
          disabled
        />
        <TextField
          key="org"
          id="org"
          label="Org:"
          autoComplete="org"
          type="text"
          fullWidth
          margin="normal"
          multiline
          value={this.props.data.messenger.organization}
          disabled
        />
        <TextField
          key="mobile"
          id="mobile"
          label="Mobile:"
          autoComplete="mobile"
          type="text"
          fullWidth
          margin="normal"
          value={this.props.data.messenger.phone}
          disabled
        />
        <TextField
          key="prefill"
          id="prefill"
          label="Message"
          autoComplete="prefill"
          type="text"
          fullWidth
          margin="normal"
          multiline
          value={this.state.message}
          onChange={event =>
            this.setState({
              message: event.target.value,
            })
          }
        />
        <TextField
          key="additional"
          id="additional"
          label="Additional Details (Optional)"
          autoComplete="additional"
          type="text"
          fullWidth
          margin="normal"
          value={this.state.additional}
          onChange={event =>
            this.setState({
              additional: event.target.value,
            })
          }
        />
      </div>
    );
  }

  button() {
    const { classes } = this.props;

    return (
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        component={Link}
        to={this.props.nextURL}
        onClick={() => this.handleSend()}
      >
        Send Request
      </Button>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Hidden smUp>
          <div className={classes.mobileRoot}>
            {this.form()}
            {this.button()}
          </div>
        </Hidden>
        <Hidden xsDown>
          <div className={classes.root}>
            {this.form()}
            {this.button()}
          </div>
        </Hidden>
      </Fragment>
    );
  }

  handleSend() {
    const fullMessage = this.state.additional
      ? `${this.props.data.messenger.message}\n${this.state.additional}`
      : this.props.data.messenger.message;

    this.props.sendMessage({
      message: fullMessage,
      to: this.props.data.messenger.phone,

      doNotSave: true,

      instanceId: this.props.data.messenger.instanceId,
      confirmationId: this.props.data.messenger.confirmationId,
    });
  }
}

Messaging.propTypes = {
  classes: PropTypes.object.isRequired,
  nextURL: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    instance: state.instances.instance,
    data: state.instances.instance.data,

    message: state.messages.message,
    isSendingMessage: state.messages.isSendingMessage,
    isGettingMessage: state.messages.isGettingMessage,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendMessage: messagesActions.sendMessage,
      getMessage: messagesActions.getMessage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Messaging));
