import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

const styles = theme => ({
  root: {
    color: theme.palette.grey[600],
    textDecoration: "none",
  },
});

function TextLink(props) {
  return <Typography component={Link} {...props} />;
}

TextLink.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextLink);
