import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Typography,
} from "@material-ui/core";
import Loading from "./Loading";
import { instancesActions } from "../store/actions";

const styles = theme => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 840,
    marginBottom: 16,
  },
  empty: {
    textAlign: "center",
  },
  createButton: {
    margin: "16px 0px",
  },
});

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    paddingRight: 4,
  },
  body: {
    paddingRight: 4,
  },
}))(TableCell);

class Instances extends Component {
  componentDidMount() {
    this.props.getInstances({
      query: { workflow: this.props.workflowId },
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isDeletingInstance ||
      prevProps.isUpdatingInstance ||
      prevProps.isCreatingInstance
    ) {
      this.props.getInstances({
        query: { workflow: this.props.workflowId },
      });
    }
  }

  render() {
    const { classes } = this.props;

    if (
      this.props.isGettingInstances ||
      this.props.isDeletingInstance ||
      this.props.isUpdatingInstance ||
      this.props.isCreatingInstance
    ) {
      return <Loading text="Loading Instances..." />;
    }

    const showEmpty = !this.props.instances || this.props.instances.length < 1;
    const showInstances = !showEmpty;

    const numberOfInstances = showEmpty ? 0 : this.props.instances.length;
    const maxNumberOfInstances = 4;
    const canCreateInstance = numberOfInstances < maxNumberOfInstances;

    return (
      <div className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <CustomTableCell>Name</CustomTableCell>
              <CustomTableCell>Created</CustomTableCell>
              <CustomTableCell>Status</CustomTableCell>
              <CustomTableCell>Last Update</CustomTableCell>
              <CustomTableCell>Actions</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {showInstances &&
              this.props.instances.map(instance => {
                return (
                  <TableRow key={instance._id}>
                    <CustomTableCell>{instance.name}</CustomTableCell>
                    <CustomTableCell>
                      {moment(instance.createdTimestamp).format("lll")}
                    </CustomTableCell>
                    <CustomTableCell>
                      {instance.isActive ? "Active" : "Inactive"}
                    </CustomTableCell>
                    <CustomTableCell>
                      {moment(instance.updatedTimestamp).format("lll")}
                    </CustomTableCell>
                    <CustomTableCell>
                      {instance.isActive && (
                        <Button
                          component={Link}
                          to={`/workflows/${this.props.workflowId}/${
                            instance._id
                          }/dapp/${instance.pages[0].id}`}
                        >
                          Open
                        </Button>
                      )}
                      <Button
                        component={Link}
                        to={`/workflows/${this.props.workflowId}/${
                          instance._id
                        }/manage`}
                      >
                        Manage
                      </Button>
                    </CustomTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {showEmpty && (
          <Typography variant="caption" className={classes.empty} gutterBottom>
            There are no instances for this workflow.
          </Typography>
        )}
        <Button
          className={classes.createButton}
          variant="contained"
          color="primary"
          disabled={!canCreateInstance}
          component={Link}
          to={`/workflows/${this.props.workflowId}/create`}
        >
          Create New Instance
        </Button>
        <Typography>
          {numberOfInstances} out of {maxNumberOfInstances} maximum instances
          allowed
        </Typography>
      </div>
    );
  }
}

Instances.propTypes = {
  classes: PropTypes.object.isRequired,
  workflowId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  instances: state.instances.instances,
  isGettingInstances: state.instances.isGettingInstances,
  isUpdatingInstance: state.instances.isUpdatingInstance,
  isDeletingInstance: state.instances.isDeletingInstance,
  isCreatingInstance: state.instances.isCreatingInstance,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getInstances: instancesActions.getInstances,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Instances));
