import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    maxWidth: theme.breakpoints.values.md,
    margin: "0 auto",
    padding: `60px ${theme.spacing.unit * 4}px`,
    [theme.breakpoints.up("md")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
});

class PageNotFound extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant="h1" color="secondary" gutterBottom>
          404
        </Typography>
        <Typography variant="h2" gutterBottom>
          We couldn’t find this page.
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(PageNotFound);
