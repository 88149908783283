import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import TextLink from "./TextLink";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  divider: {
    maxWidth: theme.breakpoints.values.md,
    margin: "0 auto",
  },
  grid: {
    [theme.breakpoints.down("sm")]: {
      padding: `0px ${theme.spacing.unit * 4}px`,
    },
    maxWidth: theme.breakpoints.values.md,
    margin: "0 auto",
    height: 100,
  },
});

function Footer(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Divider className={classes.divider} />
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.grid}
      >
        <TextLink to="/">&copy; 2018 BLOCKxStudio</TextLink>
        <TextLink to="/privacy">PRIVACY &amp; COOKIES POLICY</TextLink>
      </Grid>
    </div>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
