import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Tabs from "../modules/Instance/Tabs";
import Page from "../modules/Instance/Page";

class InstanceDApp extends Component {
  render() {
    return (
      <Fragment>
        <Tabs
          workflowId={this.props.match.params.workflowId}
          instanceId={this.props.match.params.instanceId}
          pageId={this.props.match.params.pageId}
        />
        <Page
          workflowId={this.props.match.params.workflowId}
          instanceId={this.props.match.params.instanceId}
          pageId={this.props.match.params.pageId}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstanceDApp);
