export default {
  GET_INVITES_REQUEST: "GET_INVITES_REQUEST",
  GET_INVITES_SUCCESS: "GET_INVITES_SUCCESS",
  GET_INVITES_FAILURE: "GET_INVITES_FAILURE",

  GET_INVITE_REQUEST: "GET_INVITE_REQUEST",
  GET_INVITE_SUCCESS: "GET_INVITE_SUCCESS",
  GET_INVITE_FAILURE: "GET_INVITE_FAILURE",

  DELETE_INVITE_REQUEST: "DELETE_INVITE_REQUEST",
  DELETE_INVITE_SUCCESS: "DELETE_INVITE_SUCCESS",
  DELETE_INVITE_FAILURE: "DELETE_INVITE_FAILURE",

  SEND_INVITE_REQUEST: "SEND_INVITE_REQUEST",
  SEND_INVITE_SUCCESS: "SEND_INVITE_SUCCESS",
  SEND_INVITE_FAILURE: "SEND_INVITE_FAILURE",
};
