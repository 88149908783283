import axios from "axios";
import types from "./types";
import Constants from "../../Constants";
import { actions as snackbarActions } from "../snackbar";
import Utils from "../../Utils";

// Creators
export const actions = {
  getInvites,
  deleteInvite,
  sendInvite,
};

// Implementations
function getInvites({ query = null } = {}) {
  return dispatch => {
    dispatch(request());

    const stringifiedQuery = Utils.stringify(query);

    axios({
      method: "get",
      url: `/invites?${stringifiedQuery}`,
      baseURL: Constants.BXS_API_URL,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error: Get invites request failed.`,
            variant: "error",
          })
        );
      });
  };

  function request() {
    return { type: types.GET_INVITES_REQUEST };
  }
  function success(payload) {
    return { type: types.GET_INVITES_SUCCESS, payload };
  }
  function failure() {
    return { type: types.GET_INVITES_FAILURE };
  }
}

function deleteInvite(id) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "delete",
      url: `/invites/${id}`,
      baseURL: Constants.BXS_API_URL,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error: Delete invite by id failed.`,
            variant: "error",
          })
        );
      });
  };

  function request() {
    return { type: types.DELETE_INVITE_REQUEST };
  }
  function success(payload) {
    return { type: types.DELETE_INVITE_SUCCESS, payload };
  }
  function failure() {
    return { type: types.DELETE_INVITE_FAILURE };
  }
}

function sendInvite({ fields = null } = {}) {
  return dispatch => {
    dispatch(request());
    if (!fields) {
      dispatch(failure());
      dispatch(
        snackbarActions.open({
          message: `Action error: Cannot send invite. Missing fields.`,
          variant: "error",
        })
      );
      return;
    }

    axios({
      method: "post",
      url: `/invites`, // TODO: Use Invites Service
      baseURL: Constants.BXS_API_URL,
      data: fields,
      headers: { "x-access-token": Constants.Cookies.get("token") },
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error: Send invite request failed.`,
            variant: "error",
          })
        );
      });
  };

  function request() {
    return { type: types.SEND_INVITE_REQUEST };
  }
  function success(payload) {
    return { type: types.SEND_INVITE_SUCCESS, payload };
  }
  function failure() {
    return { type: types.SEND_INVITE_FAILURE };
  }
}
