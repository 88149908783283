import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Typography, Button, TextField } from "@material-ui/core";
import { workflowsActions, instancesActions } from "../../store/actions";

import Loading from "../Loading";

const styles = theme => ({
  section: {
    marginBottom: 18,
  },
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginBottom: 18,
  },
  button: {
    margin: 8,
  },
});

class Create extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired,
    workflowId: PropTypes.string.isRequired,
  };

  state = {
    name: "",
    description: "",
  };

  componentDidMount() {
    this.props.getWorkflow(this.props.workflowId);
  }

  render() {
    const { classes } = this.props;

    if (this.props.isGettingWorkflow) {
      return <Loading text="Loading Workflow..." variant="h5" />;
    }

    if (!this.props.workflow) {
      return <Typography gutterBottom>Workflow does not exist.</Typography>;
    }

    return (
      <Fragment>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            {this.props.workflow.name}
          </Typography>
          <Typography gutterBottom>
            {this.props.workflow.description}
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h5">Create New Instance</Typography>
          <TextField
            key="name"
            id="name"
            label="Instance Name"
            autoComplete="name"
            type="text"
            fullWidth
            margin="normal"
            defaultValue={this.state.name}
            onChange={event => {
              this.setState({ name: event.target.value });
            }}
          />
          <TextField
            key="description"
            id="description"
            label="Instance Description"
            autoComplete="description"
            type="text"
            fullWidth
            multiline
            margin="normal"
            defaultValue={this.state.description}
            onChange={event => {
              this.setState({ description: event.target.value });
            }}
          />
        </div>
        <div>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() =>
              this.props.createInstance({
                fields: {
                  name: this.state.name,
                  description: this.state.description,
                  isActive: true,
                  workflow: this.props.workflow._id,
                  organization: this.props.organizationId,
                  organizationName: this.props.organization.name,
                  data: this.props.workflow.data,
                  pages: this.props.workflow.pages,
                },
              })
            }
            component={Link}
            to={`/workflows/${this.props.workflow._id}/`}
          >
            Create New Instance
          </Button>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  workflow: state.workflows.workflow,
  isGettingWorkflow: state.workflows.isGettingWorkflow,
  organizationId: state.auth.organizationId,
  organization: state.auth.organization,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getWorkflow: workflowsActions.getWorkflow,
      createInstance: instancesActions.createInstance,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Create));
